import _ from 'lodash'
import api from 'x/utils/api'
import { takeLatest, put, call, fork, all } from 'redux-saga/effects'
import * as SubscriptionActions from 'x/modules/subscription/SubscriptionState'
import * as MySubscriptionActions from 'x/modules/subscription/MySubscriptionState'
import { getSelectedStore } from 'x/redux/selectors'
import { COLORS } from 'x/config/styles'
import { isImmutable } from 'immutable'
import actions from '../../config/actions'
import * as util from '../../utils/util'
import { log } from '../../utils/util'

import p from '../../config/platform-specific'
import xCONS from '../../config/constants'

const moment = require('moment')
require('moment/locale/th')
require('moment-timezone')

moment.locale('th')

// import { fromJS } from 'immutable'
// import p from '../../config/platform-specific'
// import xCONS from '../../config/constants'
// import { setCrashlyticsUserId } from './../../utils/analytics'

export default function* watchAllSubscription() {
  yield all([fork(watchSubscriptionFetch)])
}

function* watchSubscriptionFetch() {
  log('In watchSubscriptionFetch')
  // @ts-ignore
  yield takeLatest(actions.MY_SUBSCRIPTION_FETCH, fetchMySubscription)
}

function* fetchMySubscription({ successCallback = null, failedCallback = null }) {
  log('In fetchMySubscription')
  try {
    const body = {}
    // @ts-ignore
    const res = yield call(api.postV2, api.GET_GO_SUBSCRIPTION_DETAIL, body)
    log('In fetchMySubscription res: ', res)
    const newRes = _.cloneDeep(res)
    // is_new_subscription เอาไว้ดูว่าใช่ subscription ตัวใหม่หรือไม่ เพราะปัจจุบัน (17/09/2024) จะโหลด subscription จาก GO api และมีข้อมูลบางอย่างที่ตัวเก่าไม่มี
    // ถ้าจะลบ is_new_subscription ให้ไปดู code ที่ไฟล์ AppView ของ app(มือถือ) ใน component componentDidUpdate ด้วย
    newRes.is_new_subscription = true

    if (util.isHelper()) {
      const selectedStore = getSelectedStore(p.op.getAppState())
      const selectedStoreJS = isImmutable(selectedStore) ? selectedStore.toJS() : selectedStore
      // console.log('selectedStoreJS => ', selectedStoreJS)
      const bodyHelper = {
        store_id: selectedStoreJS.id,
      }

      const response = yield call(api.postV2, api.GET_GO_SUBSCRIPTION_DETAIL, bodyHelper)
      // const response = _.has(selectedStoreJS, 'store_owner_subscription') ? selectedStoreJS.store_owner_subscription : null
      const newResponseForHlper = _.cloneDeep(response)
      // is_new_subscription เอาไว้ดูว่าใช่ subscription ตัวใหม่หรือไม่ เพราะปัจจุบัน (17/09/2024) จะโหลด subscription จาก GO api และมีข้อมูลบางอย่างที่ตัวเก่าไม่มี
      // ถ้าจะลบ is_new_subscription ให้ไปดู code ที่ไฟล์ AppView ของ app(มือถือ) ใน component componentDidUpdate ด้วย
      newResponseForHlper.is_new_subscription = true
      yield put(MySubscriptionActions.loadSubscription({ ...newRes, lastUpdated: moment() }))
      yield put(SubscriptionActions.loadSubscription({ ...newResponseForHlper, lastUpdated: moment() }))
      // Check for show warning out of order quota
      yield call(checkSubscriptionOrderQuota, newResponseForHlper)
      // Check for show warning out of package
      yield call(checkSubscriptionPackageDateTime, newResponseForHlper)
      // Check for show warning out of quota
      yield call(checkSubscriptionQuota, newResponseForHlper)

      if (_.isFunction(successCallback)) {
        successCallback(newResponseForHlper)
      }
    } else {
      yield put(MySubscriptionActions.loadSubscription({ ...newRes, lastUpdated: moment() }))
      yield put(SubscriptionActions.loadSubscription({ ...newRes, lastUpdated: moment() }))
      // Check for show warning out of order quota
      yield call(checkSubscriptionOrderQuota, newRes)
      // Check for show warning out of package
      yield call(checkSubscriptionPackageDateTime, newRes)
      // Check for show warning out of quota
      yield call(checkSubscriptionQuota, newRes)
      if (_.isFunction(successCallback)) {
        successCallback(newRes)
      }
    }

    // yield call(handleSubscriptionFromResponse, { subscription: newRes })
    // if (_.isFunction(successCallback)) {
    //   successCallback(res)
    // }
  } catch (error) {
    log('In fetchMySubscription error: ', error)
    yield put({ type: actions.MY_SUBSCRIPTION_FETCH_FAILED })
    if (_.isFunction(failedCallback)) {
      failedCallback(error)
    }
  }
}

export function* handleSubscriptionFromResponse(res) {
  if (res && res.subscription) {
    const { subscription } = res

    // log('openSubscriptionWarning => ', appGlobalMethods.openSubscriptionWarning)

    // โหลดเข้าทั้ง mySubscription และ subscription ถ้ามีของ store owner's subscription ค่อยมาทับ subscription ทีหลัง
    yield put(MySubscriptionActions.loadSubscription({ ...subscription, lastUpdated: moment() }))
    yield put(SubscriptionActions.loadSubscription({ ...subscription, lastUpdated: moment() }))

    // ถ้าฉันเป็นผู้ช่วยร้าน จะโหลด store owner's subscription เข้าไปใช้
    const selectedStore = getSelectedStore(p.op.getAppState())
    const selectedStoreJS = isImmutable(selectedStore) ? selectedStore.toJS() : selectedStore
    // console.log('selectedStoreJS => ', selectedStoreJS)
    if (_.has(selectedStoreJS, 'store_owner_subscription')) {
      yield put(SubscriptionActions.loadSubscription({ ...selectedStoreJS.store_owner_subscription, lastUpdated: moment() }))
    }

    // Check for show warning out of order quota
    yield call(checkSubscriptionOrderQuota, subscription)
    // Check for show warning out of package
    yield call(checkSubscriptionPackageDateTime, subscription)

    util.log('handleSubscriptionFromResponse subscription => ', subscription)
  }
}

export function* checkSubscriptionOrderQuota(subscription: { [key: string]: any }) {
  const {
    order_count_curr,
    order_count_quota,
    end_billing_date,
    // exceeded_at,
    is_new_subscription,
    order_count_quota_exceeded_at,
  } = subscription
  if (_.isNil(is_new_subscription)) {
    return
  }
  const { STORAGE_KEYS, SERVER_DATETIME_FORMAT } = xCONS
  const availableOrder = order_count_quota - order_count_curr
  const availableOrderPercent = Math.round((availableOrder / order_count_quota) * 100)

  const warningMessage = {
    type: null,
    message: `คุณใช้งานได้อีก ${availableOrder} ออเดอร์`,
    closeable: true,
    priority: 2,
  }
  // console.log('order_count_quota_exceeded_at => ', order_count_quota_exceeded_at)
  if (!_.isNil(order_count_quota_exceeded_at)) {
    const exceeded_at_moment = moment(order_count_quota_exceeded_at)
    const timeLeft = exceeded_at_moment.clone().diff(moment(), 'd')
    // console.log('timeLeft => ', timeLeft)
    if (timeLeft < 0) {
      warningMessage.message =
        'คุณจะไม่สามารถเปิดและรับออเดอร์จากตัวแทนได้ คุณสามารถเลือกอัพเกรดแพ็กเกจของคุณ หรือ ซื้อแพ็กเกจเสริมเพิ่มออเดอร์ เพื่อที่จะสามารถใช้งานได้ต่อไป'
      warningMessage.closeable = true
      warningMessage.type = 'danger'
      warningMessage.priority = 4
      yield put(MySubscriptionActions.showWarning(warningMessage))
      return
    }
  }

  const now = moment()
  // const usagePercentOrder = (order_count_curr / order_count_quota) * 100

  // ถ้ามีออเดอร์อยู่ ตัดขั้นตอน validation ออกมา แล้ว reset orderTracker
  if (availableOrderPercent > 20) {
    // ถ้า available %order > 20
    const initTracker = {
      exceededAt: order_count_quota_exceeded_at || null,
      lastWarning: null,
      lastUpdated: now.format(SERVER_DATETIME_FORMAT),
    }
    yield call(p.op.storageSet, STORAGE_KEYS.SUBSCRIPTION_ORDER_TRACKER, initTracker)
  } else {
    // ถ้า available %order < 20
    // log('availableOrderPercent => ', availableOrderPercent)
    let orderTracker = yield call(p.op.storageGet, STORAGE_KEYS.SUBSCRIPTION_PACKAGE_QUOTA_TRACKER)
    if (!orderTracker) {
      orderTracker = {
        exceededAt: order_count_quota_exceeded_at || null,
        lastWarning: null,
        lastUpdated: now.format(SERVER_DATETIME_FORMAT),
      }
    }

    if (order_count_quota_exceeded_at) {
      orderTracker.exceededAt = order_count_quota_exceeded_at || orderTracker.exceededAt
    }

    // MOCK ::
    // orderTracker.exceededAt = now.clone().add(42, 'h').format(SERVER_DATETIME_FORMAT)
    // orderTracker.exceededAt = now.clone()
    // orderTracker.exceededAt = now.clone().add(5, 'm').format(SERVER_DATETIME_FORMAT)
    // orderTracker.exceededAt = now.clone().add(0, 'm').format(SERVER_DATETIME_FORMAT)
    // orderTracker.lastWarning = now.clone().subtract(27, 'h').format(SERVER_DATETIME_FORMAT)
    // log('orderTracker => ', orderTracker)

    const { exceededAt, lastWarning } = orderTracker

    // if (!lastWarning) {
    //   orderTracker.lastWarning = now.clone().format(SERVER_DATETIME_FORMAT)
    // }

    // let lastestWarning = moment().format(SERVER_DATETIME_FORMAT)
    const txtFromNowToBillingDate = moment(end_billing_date, 'YYYY-MM-DD').endOf('d').fromNow()
    const txtBreakTheOrder = 'คุณจะไม่สามารถเปิดและรับออเดอร์จากตัวแทนได้'
    const txtEndOfBillingDate = `ก่อนหมดรอบบิลใน ${txtFromNowToBillingDate} หากต้องการเพิ่มจำนวนออเดอร์กรุณาติดต่อทีมงาน`
    const txtContactXSelly = ' กดปุ่มดูรายละเอียดเพื่อเลือกแพ็กเกจใช้งานที่เหมาะสมกับคุณ เนื่องจาก'
    const txtOutOfOrder = ' คุณใช้ออเดอร์เกินจำนวนโควตา'

    const latestWarningTime = lastWarning ? moment(lastWarning, SERVER_DATETIME_FORMAT) : null

    if (availableOrderPercent <= 0) {
      warningMessage.message = txtOutOfOrder
      warningMessage.message += txtContactXSelly

      if (!exceededAt) {
        // orderTracker.exceededAt = exceeded_at ? exceeded_at : now.clone().add(48, 'h').format(SERVER_DATETIME_FORMAT)
        orderTracker.exceededAt = order_count_quota_exceeded_at || now.clone().format(SERVER_DATETIME_FORMAT)
      }

      // const outOfOrderTime = moment(orderTracker.exceededAt, SERVER_DATETIME_FORMAT)
      // const outOfOrderTime = moment(orderTracker.exceededAt, SERVER_DATETIME_FORMAT).add(48, 'h')
      const exceededAtOrder = !_.isNil(order_count_quota_exceeded_at) ? order_count_quota_exceeded_at : orderTracker.exceededAt
      const outOfOrderTime = moment(exceededAtOrder, SERVER_DATETIME_FORMAT).add(48, 'h')
      // log('outOfOrderTime => ', outOfOrderTime)
      // const diffTime = now.diff(outOfOrderTime, 'm')
      // log('diffTime => ', diffTime)

      // if (diffTime < 0) {
      //   // ยังเหลือเวลา
      //   const txtFromNowToCutTime = outOfOrderTime.fromNow()
      //   warningMessage.message = warningMessage.message + ` ${txtFromNowToCutTime} `
      // } else {
      //   // หมดเวลา
      //   warningMessage.message = warningMessage.message + ' ตอนนี้'
      // }

      if (now.isBefore(outOfOrderTime, 'm')) {
        // ยังเหลือเวลา
        const txtFromNowToCutTime = outOfOrderTime.fromNow()
        warningMessage.message += `${txtFromNowToCutTime} `
      } else {
        // หมดเวลา
        warningMessage.message += 'ตอนนี้'
      }

      // ใช้ออเดอร์เกินโควค้า
      warningMessage.message += txtBreakTheOrder
      // warningMessage.message = warningMessage.message + txtEndOfBillingDate
      warningMessage.closeable = true
      warningMessage.type = 'danger'
    } else if (availableOrderPercent <= 10) {
      // เหลือ 10%
      if (Math.abs(now.diff(latestWarningTime, 'h')) >= 6 || !latestWarningTime) {
        warningMessage.message += txtEndOfBillingDate
        warningMessage.closeable = true
        warningMessage.type = 'warning'
        orderTracker.lastWarning = now.format(SERVER_DATETIME_FORMAT)
        orderTracker.lastUpdated = now.format(SERVER_DATETIME_FORMAT)
      }
    } else if (availableOrderPercent <= 20) {
      // เหลือ 20 %
      if (Math.abs(now.diff(latestWarningTime, 'h')) >= 24 || !latestWarningTime) {
        warningMessage.message += txtEndOfBillingDate
        // warningMessage.message = warningMessage.message + ` หลังจากคุณใช้งานออเดอร์จนหมด 48 ชั่วโมง ${txtBreakTheOrder}`
        warningMessage.closeable = true
        warningMessage.type = 'warning'
        orderTracker.lastWarning = now.format(SERVER_DATETIME_FORMAT)
        orderTracker.lastUpdated = now.format(SERVER_DATETIME_FORMAT)
      }
    } else {
      // Reset รอบบิล
      warningMessage.closeable = true
      warningMessage.type = null
      orderTracker.exceededAt = order_count_quota_exceeded_at || null
      orderTracker.lastWarning = null
      orderTracker.lastUpdated = null
    }

    yield call(p.op.storageSet, STORAGE_KEYS.SUBSCRIPTION_ORDER_TRACKER, orderTracker)

    if (warningMessage.type && warningMessage.type) {
      yield put(MySubscriptionActions.showWarning(warningMessage))
    }
    // let afterOrderTracking = yield call(p.op.storageGet, STORAGE_KEYS.SUBSCRIPTION_ORDER_TRACKER)
    // log('afterOrderTracking => ', afterOrderTracking)
  }
}

export function* checkSubscriptionPackageDateTime(subscription: { [key: string]: any }) {
  const { is_new_subscription, type } = subscription
  if (_.isNil(is_new_subscription)) {
    return
  }

  // ตรวจสอบอายุการใช้งานของแพ็กเกจ
  const thisWarning: {
    type: 'warning' | 'danger' | 'success'
    message: string
    closeable: boolean
    priority: number
  } = {
    type: 'warning',
    message: 'คุณใช้งานได้อีก 2 ออเดอร์',
    closeable: true,
    priority: 0,
  }
  // const subscription: any = this.props.subscription.toJS()
  let userPackage = 'Free'
  if (type === 1) {
    userPackage = 'Free'
  } else if (type === 2) {
    userPackage = 'Bronze'
  } else if (type === 3) {
    userPackage = 'Silver'
  } else if (type === 4) {
    userPackage = 'Gold'
  } else if (type === 5) {
    userPackage = 'Platinum'
  } else if (type === 999) {
    userPackage = 'ทดลองใช้'
  }
  // console.log(type)
  if (type === 1) {
    // หากเป็นแพ็กเกจฟรีให้รีเทิร์ท
    // ถ้ามีข้อมูลค้าง ลบออก เพราะว่า แพ็กฟรี ไม่มีหมดอายุ
    p.op.storageClear(xCONS.STORAGE_KEYS.SUBSCRIPTION_PACKAGE_TRACKER)
    return
  }
  if (subscription.m_e_date === undefined || subscription.m_e_date === null) {
    // หากไม่มีวันหมดอายุการใช้งานให้รีเทิร์ท
    // ถ้ามีข้อมูลค้าง ลบออก เพราะว่า แพ็กฟรี ไม่มีหมดอายุ
    p.op.storageClear(xCONS.STORAGE_KEYS.SUBSCRIPTION_PACKAGE_TRACKER)
    return
  }
  // const from = moment(subscription.m_s_date)
  const now = moment()
  // const test = moment("2018-10-03 23:59:59")
  const end = moment(util.getSubcriptionEndDate(subscription)) // อายุการใช้งานงานวันสุดท้ายของแพ็เกจ
  // const end = moment(subscription.m_e_date) // อายุการใช้งานงานวันสุดท้ายของแพ็เกจ
  let timeLeft = end.clone().diff(now.clone(), 'd') // ช่วงเวลาที่เหลือของอายุการใช้งาน ( 0 วันเท่ากับ วันนี้ )
  const checkTimeLeft = end.clone().diff(now.clone(), 's') // เอาไว้ตรวจสอบ หากอายุการใช้งานเกลือ 2 วัน เริ่มแสดงผลเป็นชั่วโมง จากที่ เป็นวัน
  if (checkTimeLeft > 0 && timeLeft === 0) {
    timeLeft = 1
  } // เพิ่มค่าอายุการใช้งานใหม่เพื่อให้แสดงผลได้อยางถูกต้อง
  if (checkTimeLeft < 172800 && checkTimeLeft > 86400) {
    timeLeft = 2
  } // เพิ่มค่าอายุการใช้งานใหม่เพื่อให้แสดงผลได้อยางถูกต้อง
  //  console.log(checkTimeLeft + ' : Minutes : ' + checkTimeLeftForMinutes + ' : DAY : ' + timeLeft)
  const orderTracker = {
    canCreatOrder: true, // เอาไว้ตรวจสอบ หรือเป็น false จะไม่สามารถเปิดออเดอร์ได้อีก
    warningDateTime: now.clone().format(xCONS.SERVER_DATETIME_FORMAT), // เวลาที่แสดงแจ้งเตือนครั้งล่าสุด
    nextUpdated: subscription.m_e_date, // แจ้งเตือนครั้งต่อไปอีก hour ชั่วโมงข้างหน้า
  }

  let canCreatOrder = true
  const timestampFunction = () => {
    // เมื่อมีการ CellBack ฟั่งชั่นนี้จะถูกเรียกทำงาน โดยจะทำการ storageSet SUBSCRIPTION_PACKAGE_TRACKER
    const newNow = moment()
    let hour = 48 // เอาไว้สำหรับการแสดงผลครั้งต่อไปอีกกี่ชั่วโมง
    if (timeLeft > 2) {
      hour = 48
    } else if (timeLeft < 3 && timeLeft > 0) {
      hour = 6
    } else if (timeLeft === 0) {
      hour = 1
    } else if (timeLeft < 0 && timeLeft > -3) {
      hour = 0
    } else if (timeLeft < -2) {
      hour = 0
      canCreatOrder = false
    }
    const orderTracker = {
      canCreatOrder: true,
      warningDateTime: newNow.clone().format(xCONS.SERVER_DATETIME_FORMAT),
      nextUpdated: newNow.clone().add(hour, 'h').format(xCONS.SERVER_DATETIME_FORMAT), // แจ้งเตือนครั้งต่อไปอีก hour ชั่วโมงข้างหน้า
    }
    // const sixHour = newNow.clone().add(6, 'h').format(SERVER_DATETIME_FORMAT)
    // console.log('------------------------------------------- ' + hour)
    orderTracker.canCreatOrder = canCreatOrder
    p.op.storageSet(xCONS.STORAGE_KEYS.SUBSCRIPTION_PACKAGE_TRACKER, orderTracker, true)
  }

  // ********************  START HARD CODE SET SUBSCRIPTION_ORDER_TRACKER *******************
  //  const orderTracker2 = {
  //    exceededAt: null,
  //    lastWarning: test.format(SERVER_DATETIME_FORMAT),
  //    lastUpdated: test.format(SERVER_DATETIME_FORMAT)
  //  }
  //  p.op.storageSet(xCONS.STORAGE_KEYS.SUBSCRIPTION_ORDER_TRACKER, orderTracker2, true)
  // ********************  END HARD CODE SET SUBSCRIPTION_ORDER_TRACKER *******************
  if (timeLeft >= 16) {
    // ถ้าอายุการใช้งานเหลือมากกว่า 15 วัน
    p.op.storageSet(xCONS.STORAGE_KEYS.SUBSCRIPTION_PACKAGE_TRACKER, orderTracker, true)
  } else if (timeLeft < 16 && timeLeft > 2) {
    // ถ้าอายุการใช้งานเหลือน้อยกว่า 16 วัน และมากว่า 2 วัน
    const storageGet = p.op.storageGet(xCONS.STORAGE_KEYS.SUBSCRIPTION_PACKAGE_TRACKER, true)
    let lastWarning = moment()
    if (storageGet === null) {
      lastWarning = moment().subtract(1, 'days')
    } else {
      // @ts-ignore
      lastWarning = moment(storageGet.nextUpdated)
    }
    const checkWarning = moment.min(now, lastWarning)
    if (checkWarning === lastWarning) {
      thisWarning.message = `อีก ${timeLeft} วัน แพ็กเกจ ${userPackage} ของคุณจะหมดอายุ\nคุณสามารถเริ่มชำระค่าบริการ เพื่อต่อแพ็กเกจของคุณได้แล้ววันนี้`
      thisWarning.priority = 1
      // this.props.showSubscription(thisWarning, timestampFunction)
      yield put(MySubscriptionActions.showWarning(thisWarning, timestampFunction))
    }
  } else if (timeLeft === 1 || timeLeft === 2) {
    // ถ้าอายุการใช้งานเหลือ 1 หรือ 2 วัน
    const storageGet = p.op.storageGet(xCONS.STORAGE_KEYS.SUBSCRIPTION_PACKAGE_TRACKER, true)
    let lastWarning = moment()
    if (storageGet === null) {
      lastWarning = moment().subtract(1, 'days')
    } else {
      // @ts-ignore
      lastWarning = moment(storageGet.nextUpdated)
    }
    const hours = lastWarning.clone().diff(now.clone(), 'h')
    if (hours > 24) {
      lastWarning = moment().subtract(1, 'days')
    }
    // console.log(hours)
    const checkWarning = moment.min(now.clone(), lastWarning)
    if (checkWarning === lastWarning) {
      thisWarning.message = `อีก ${timeLeft} วัน แพ็กเกจ ${userPackage} ของคุณจะหมดอายุ\nคุณสามารถเริ่มชำระค่าบริการ เพื่อต่อแพ็กเกจของคุณได้แล้ววันนี้`
      thisWarning.priority = 3
      // this.props.showSubscription(thisWarning, timestampFunction)
      yield put(MySubscriptionActions.showWarning(thisWarning, timestampFunction))
    }
  } else if (timeLeft === 0) {
    // ถ้าอายุการใช้งานเหลือแค่วันนี้
    // const test = moment("2018-10-11 14:59:59")
    // const storageGet = {}
    const storageGet = p.op.storageGet(xCONS.STORAGE_KEYS.SUBSCRIPTION_PACKAGE_TRACKER, true)
    let lastWarning = moment()
    if (storageGet === null) {
      lastWarning = moment().subtract(1, 'days')
    } else {
      // @ts-ignore
      lastWarning = moment(storageGet.nextUpdated)
    }
    // const checkWarning = moment.min(now.clone(), lastWarning)
    // if (checkWarning === lastWarning) {
    thisWarning.type = 'danger'
    thisWarning.message = `วันนี้ แพ็กเกจ ${userPackage} ของคุณจะหมดอายุ\nคุณสามารถเริ่มชำระค่าบริการ เพื่อต่อแพ็กเกจของคุณได้เลย`
    thisWarning.priority = 3
    // this.props.showSubscription(thisWarning, timestampFunction)
    yield put(MySubscriptionActions.showWarning(thisWarning, timestampFunction))
    // }
  } else if (timeLeft < 0 && timeLeft > -3) {
    // ถ้าอายุการใช้งานหมด แต่ยังอยู่ในช่วงบวกให้ 2 วัน
    const storageGet = p.op.storageGet(xCONS.STORAGE_KEYS.SUBSCRIPTION_PACKAGE_TRACKER, true)
    let lastWarning = moment()
    if (storageGet === null) {
      lastWarning = moment().subtract(1, 'days')
    } else {
      // @ts-ignore
      lastWarning = moment(storageGet.nextUpdated)
    }
    // const checkWarning = moment.min(now.clone(), lastWarning)
    const addTwoDay = moment(subscription.m_e_date).add(3, 'd') // เพิ่มอายุการใช้งาน 2 วัน ให้ใส่เลข 3 จะได้ช่วงวันที่ถูกต้อง
    const hours = addTwoDay.clone().diff(now.clone(), 'h')
    const minutes = addTwoDay.clone().diff(now.clone(), 'm')
    const second = addTwoDay.clone().diff(now.clone(), 's')
    let countdown = `${hours} ชั่วโมง`
    if (hours === 0) {
      if (minutes === 0) {
        countdown = `${second} วินาที`
      } else {
        countdown = `${minutes} นาที`
      }
    }
    // if (checkWarning === lastWarning) {
    thisWarning.type = 'danger'
    thisWarning.message = `แพ็กเกจ ${userPackage} ของคุณหมดอายุแล้ว\nคุณจะไม่สามารถเปิดและรับออเดอร์จากตัวแทนได้อีกภายใน ${countdown}`
    thisWarning.priority = 3
    // this.props.showSubscription(thisWarning, timestampFunction)
    yield put(MySubscriptionActions.showWarning(thisWarning, timestampFunction))
    // }
  } else if (timeLeft < -2) {
    // ถ้าอายุการใช้งานหมดแล้วหลังจากบวกให้ 2 วัน
    const storageGet = p.op.storageGet(xCONS.STORAGE_KEYS.SUBSCRIPTION_PACKAGE_TRACKER, true)
    let lastWarning = moment()
    if (storageGet === null) {
      lastWarning = moment().subtract(1, 'days')
    } else {
      // @ts-ignore
      lastWarning = moment(storageGet.nextUpdated)
    }
    // const checkWarning = moment.min(now.clone(), lastWarning)
    // if (checkWarning === lastWarning) {
    thisWarning.type = 'danger'
    thisWarning.message = `แพ็กเกจ ${userPackage} ของคุณหมดอายุแล้ว\nคุณจะไม่สามารถเปิดและรับออเดอร์จากตัวแทนได้อีก\nกรุณาติดต่อทีมงาน Xselly`
    thisWarning.priority = 3
    thisWarning.closeable = false
    // this.props.showSubscription(thisWarning, timestampFunction)
    yield put(MySubscriptionActions.showWarning(thisWarning, timestampFunction))
    // }
  }

  // yield call(p.op.storageSet, xCONS.STORAGE_KEYS.SUBSCRIPTION_ORDER_TRACKER, orderTracker)

  // if (warningMessage.type && warningMessage.type) {
  //   yield put(MySubscriptionActions.showWarning(warningMessage))
  // }
}

export function* checkSubscriptionQuota(subscription: { [key: string]: any }) {
  const { is_new_subscription } = subscription
  if (_.isNil(is_new_subscription)) {
    return
  }

  const getWarning = util.checkSubscriptionAllQuota(subscription)
  if (_.isNil(getWarning)) {
    // p.op.storageClear(xCONS.STORAGE_KEYS.SUBSCRIPTION_PACKAGE_QUOTA_TRACKER)
    return
  }
  // @ts-ignore
  const warningName = util.getWraningNameByKey(getWarning.key)
  let messageWaring = ''
  if (getWarning.timeLeft > 0) {
    messageWaring = `คุณสามารถใช้งานระบบได้อีกแค่ ${getWarning.timeLeft} ชั่วโมง เพราะคุณใช้งาน ${warningName} เกินโควต้า`
  }
  if (getWarning.timeLeft <= 0) {
    messageWaring = `คุณไม่สามารถใช้งานระบบได้อีก เพราะคุณใช้งาน ${warningName} เกินโควตา`
  }

  const warningMessage = {
    type: getWarning.bgColor === COLORS.BRAND_Danger ? 'danger' : 'warning',
    message: messageWaring,
    closeable: true,
    priority: getWarning.bgColor === COLORS.BRAND_Danger ? 3 : 2,
  }

  if (warningMessage.type) {
    // @ts-ignore
    yield put(MySubscriptionActions.showQuotaWarning(warningMessage))
  }
}
