import { SHARED_AUTH_SCREENS } from 'xui/modules/navigator/auth-screens'
import EditCountdownTimeView from 'xui/modules/storeWebsite/EditView/EditCountdownTimeView'
import OrderViewContainer from '../order/OrderViewContainer'
import OrderQuickAddressViewContainer from '../order/OrderQuickAddressViewContainer'
import PaymentViewContainer from '../payment/PaymentViewContainer'
import PaymentListViewContainer from '../payment/PaymentListViewContainer'
import ProductViewContainer from '../product/ProductViewContainer'
import ProductListViewContainer from '../product/ProductListViewContainer'
import PGSelectorViewContainer from '../product/PGSelectorViewContainer'
import ProductWarehousesPicker from '../product/ProductWarehousesPickerContainer'
import ShipViewContainer from '../shipment/ShipViewContainer'
import OrderShipListViewContainer from '../order/OrderShipListViewContainer'
import AddCoverSheetNoteContainer from '../order/AddCoverSheetNoteContainer'
import AddCoverSheetViewContainer from '../order/AddCoverSheetViewContainer'
import OrderPrivateAndPublicNotesContainer from '../order/OrderPrivateAndPublicNotesContainer'
import MarketplaceOrderView from '../order/MarketplaceOrderViewContainer'
import ErpOrderViewContainer from '../order/ErpOrderViewContainer'
import WebOrderCopyTemplateView from '../order/WebOrderCopyTemplateView'
import ChannelListViewContainer from '../channels/ChannelListViewContainer'
import PrintShippingLabelView from '../print/PrintShippingLabelViewContainer'
import ChannelViewContainer from '../channels/ChannelViewContainer'
import PaymentPTTTransactionsContainer from '../payment/PaymentPTTTransactionsContainer'
import StoreSettingXShippingViewContainer from '../store/storeSetting/xshipping/StoreSettingXShippingViewContainer'

import StoreSettingXShippingJAndTViewContainer from '../store/storeSetting/xshipping/StoreSettingXShippingJAndTViewContainer'
import JAntTConnectViewContainer from '../store/storeSetting/xshipping/JAntTConnectViewContainer'
import AddressXShippingViewContainer from '../store/storeSetting/xshipping/AddressXShippingViewContainer'
import StoreSettingXShippingKerryViewContainer from '../store/storeSetting/xshipping/StoreSettingXShippingKerryViewContainer'
import StoreSettingXShippingFlashViewContainer from '../store/storeSetting/xshipping/StoreSettingXShippingFlashViewContainer'
import StoreSettingXShippingEMSViewContainer from '../store/storeSetting/xshipping/StoreSettingXShippingEMSViewContainer'
import StoreSettingXShippingEcoPostViewContainer from '../store/storeSetting/xshipping/StoreSettingXShippingEcoPostViewContainer'
import StoreSettingXShippingDHLViewContainer from '../store/storeSetting/xshipping/StoreSettingXShippingDHLViewContainer'
import StoreSettingXShippingNinjaVanViewContainer from '../store/storeSetting/xshipping/StoreSettingXShippingNinjaVanViewContainer'
import StoreSettingXShippingSCGViewContainer from '../store/storeSetting/xshipping/StoreSettingXShippingSCGViewContainer'
import StoreSettingXShippingBestViewContainer from '../store/storeSetting/xshipping/StoreSettingXShippingBestViewContainer'
import CustomCreateOrderViewContainer from '../order/CustomCreateOrderViewContainer'
import CustomOrderViewContainer from '../order/CustomOrderViewContainer'
import MultiParcelViewContainer from '../order/MultiParcelViewContainer'
import SelectedProductMultiParcelViewContainer from '../order/SelectedProductMultiParcelViewContainer'
import CCustomerListViewContainer from '../contact/CCustomerListViewContainer'
import CCustomerEditingViewContainer from '../contact/CCustomerEditingViewContainer'
import ContactViewContainer from '../contact/ContactViewContainer'
import PGSelectorOnlyViewContainer from '../productGroup/PGSelectorOnlyViewContainer'

import ResellerRegisterView from '../reseller/ResellerRegisterView'
import ResellerImportViewContainer from '../reseller/ResellerImportViewContainer'

import importExcelListViewContainer from '../importExcel/importExcelListViewContainer'
import importProductQTYViewContainer from '../importExcel/importProductQTYViewContainer'
import ImportProductBarcodeViewContainer from '../importExcel/ImportProductBarcodeViewContainer'
import ImportAddProductViewContainer from '../importExcel/ImportAddProductViewContainer'
import ImportExcelCODViewContainer from '../importExcel/ImportExcelCODViewContainer'
import ImportWarehouseExcelFromShipjungContainer from '../importExcel/ImportWarehouseExcelFromShipjungContainer'
import importEditProductWarehouseBySKUContainer from '../importExcel/importEditProductWarehouseBySKUContainer'
import importReduceProductWarehouseBySKUContainer from '../importExcel/importReduceProductWarehouseBySKUContainer'
import importAddProductWarehouseBySKUContainer from '../importExcel/importAddProductWarehouseBySKUContainer'
import ImportAddCustomerViewContainer from '../importExcel/ImportAddCustomerViewContainer'
import StoreOrdersDonutListViewContainer from '../order/StoreOrdersDonutListViewContainer'
import ImportExcelCreateOrderViewContainer from '../importExcel/ImportExcelCreateOrderViewContainer'

import StoreSettingListViewContainer from '../store/storeSetting/StoreSettingListViewContainer'
import StoreSettingAllPrintingShippingLabelContainer from '../store/storeSetting/StoreSettingAllPrintingShippingLabelContainer'
import StoreSettingPaperangContainer from '../store/storeSetting/StoreSettingPaperangContainer'
import StoreSettingBillViewContainer from '../store/storeSetting/StoreSettingBillViewContainer'
import StoreSettingPrintingBarcodeContainer from '../store/storeSetting/StoreSettingPrintingBarcodeContainer'
import StoreSettingPDFShippingLabelContainer from '../store/storeSetting/StoreSettingPDFShippingLabelContainer'
import StoreSettingStickerViewContainer from '../store/storeSetting/StoreSettingStickerViewContainer'
import AssistantGroupListViewContainer from '../store/storeSetting/AssistantGroupListViewContainer'
import AssistantMemberViewContainer from '../store/storeSetting/AssistantMemberViewContainer'
import AssistantGroupViewContainer from '../store/storeSetting/AssistantGroupViewContainer'
import CategoryListViewContainer from '../store/storeSetting/CategoryListViewContainer'
import CategoryPropertyViewContainer from '../store/storeSetting/CategoryPropertyViewContainer'
import StoreSettingGeneralContainer from '../store/storeSetting/StoreSettingGeneralContainer'
import StoreSettingPeakViewContainer from '../store/storeSetting/StoreSettingPeakViewContainer'
import AddressListViewContainer from '../address/AddressListViewContainer'
import AddressViewContainer from '../address/AddressViewContainer'
import BankEditViewContainer from '../store/storeSetting/BankEditViewContainer'

import SettingViewContainer from '../setting/SettingViewContainer'
import AboutViewContainer from '../about/AboutViewContainer'
import SettingResetPasswordViewContainer from '../setting/SettingResetPasswordViewContainer'
import SettingEmailViewContainer from '../setting/SettingEmailViewContainer'
import ProfileViewContainer from '../profile/ProfileViewContainer'

import ResellerListViewContainer from '../reseller/ResellerListViewContainer'
import CustomerViewContainer from '../customer/CustomerViewContainer'
import CustomerEditProfileViewContainer from '../customer/CustomerEditProfileViewContainer'

import ResellerApprovalListViewContainer from '../reseller/ResellerApprovalListViewContainer'

import ReportListViewContainer from '../report/ReportListViewContainer'
import ReportStockViewContainer from '../report/ReportStockViewContainer'
import ReportWarehouseAdjustmentContainer from '../report/ReportWarehouseAdjustmentContainer'
import CompleteSalesReportViewContainer from '../report/CompleteSalesReportViewContainer'
import ReportRequisitionViewContainer from '../report/ReportRequisitionViewContainer'
import ReportShippingViewContainer from '../report/ReportShippingViewContainer'
import ReportSalesByChannelViewContainer from '../report/ReportSalesByChannelViewContainer'
import ReportResellerViewContainer from '../report/ReportResellerViewContainer'
import ReportPeakViewContainer from '../report/ReportPeakViewContainer'
import DailySalesReportViewContainer from '../report/DailySalesReportViewContainer'
import ReportProductDailySalesContainer from '../report/ReportProductDailySalesContainer'
import ReportHelperSalesContainer from '../report/ReportHelperSalesContainer'
import ReportPaymentContainer from '../report/ReportPaymentContainer'

import QuickShipViewContainer from '../shipment/QuickShipViewContainer'

import ProductGroupListViewContainer from '../productGroup/ProductGroupListViewContainer'
import ProductGroupViewContainer from '../productGroup/ProductGroupViewContainer'

import UserGroupViewContainer from '../userGroup/UserGroupViewContainer'
import UserGroupListViewContainer from '../userGroup/UserGroupListViewContainer'
import ProductGroupSettingForUserGroupContainer from '../userGroup/ProductGroupSettingForUserGroupContainer'
import UserGroupResellerTabsContainer from '../userGroup/UserGroupResellerTabsContainer'
import ResellerChangeGroupViewContainer from '../userGroup/ResellerChangeGroupViewContainer'
import ResellerManageViewContainer from '../reseller/ResellerManageViewContainer'
import SubscriptionViewContainer from '../subscription/SubscriptionViewContainer'
import SelectPackageViewContainer from '../subscription/SelectPackageViewContainer'
import PaymentSubscriptionPackageViewContainer from '../subscription/PaymentSubscriptionPackageViewContainer'
import SelectAddOnPackageViewContainer from '../subscription/SelectAddOnPackageViewContainer'
import StoreWebsiteListViewContainer from '../storeWebsite/StoreWebsiteListViewContainer'
import StoreWebsiteViewContainer from '../storeWebsite/StoreWebsiteViewContainer'
import SalePageViewContainer from '../storeWebsite/SalePageViewContainer'
import SalePageSettingWebViewContainer from '../storeWebsite/SalePageSettingWebViewContainer'
import SalePageEditInfoViewContainer from '../storeWebsite/SalePageEditInfoViewContainer'
import SalePagePixelViewContainer from '../storeWebsite/SalePagePixelViewContainer'
import TextEditorViewContainer from '../textEditor/TextEditorViewContainer'
import CreateStoreWebsiteViewContainer from '../storeWebsite/CreateStoreWebsiteViewContainer'
import CreateSalePageViewContainer from '../storeWebsite/CreateSalePageViewContainer'
import StoreWebsiteSettingPaymentViewContainer from '../storeWebsite/StoreWebsiteSettingPaymentViewContainer'
import StoreWebsiteSettingOptionsOrderViewContainer from '../storeWebsite/StoreWebsiteSettingOptionsOrderViewContainer'
import StoreWebsiteCreateOptionsOrderViewContainer from '../storeWebsite/StoreWebsiteCreateOptionsOrderViewContainer'
import SalePageSelectProductViewContainer from '../storeWebsite/SalePageSelectProductViewContainer'
import SalePageAddItemWebViewContainer from '../storeWebsite/SalePageAddItemWebViewContainer'
import StoreWebsiteSelectedOptionModeViewContainer from '../storeWebsite/StoreWebsiteSelectedOptionModeViewContainer'
import ERPListViewContainer from '../erp/ERPListViewContainer'
import ERPCreateViewContainer from '../erp/ERPCreateViewContainer'
import ERPCreatePeakViewContainer from '../erp/ERPCreatePeakViewContainer'
import ERPDetailViewContainer from '../erp/ERPDetailViewContainer'
import ERPPaymentAccountViewContainer from '../erp/ERPPaymentAccountViewContainer'
import ERPEditPaymentAccountViewContainer from '../erp/ERPEditPaymentAccountViewContainer'
import ERPChannelContactContainer from '../erp/ERPChannelContactContainer'
import ERPShippingSettingContainer from '../erp/ERPShippingSettingContainer'

import InAppPurchaseViewContainer from '../subscription/InAppPurchaseViewContainer'
import InAppPurchasePerPackageViewContainer from '../subscription/InAppPurchasePerPackageViewContainer'

import ActivePackageDetailContainer from '../subscription/ActivePackageDetailContainer'

export const AUTH_SCREENS = {
  ...SHARED_AUTH_SCREENS,

  OrderView: {
    screen: OrderViewContainer,
    path: 'order',
    // navigationOptions: {
    //   isAlwaysActive: true,
    // },
  },
  OrderViewCreate: {
    screen: OrderViewContainer,
    path: 'order',
    navigationOptions: {
      isAlwaysActive: true,
    },
  },
  PaymentView: {
    screen: PaymentViewContainer,
    path: 'payment',
  },
  PaymentListView: {
    screen: PaymentListViewContainer,
    path: 'payments',
  },
  ProductList: {
    screen: ProductListViewContainer,
    path: 'products',
    navigationOptions: {
      isAlwaysActive: true,
    },
  },
  ProductView: {
    screen: ProductViewContainer,
    path: 'product',
  },
  OrderQuickAddressView: {
    screen: OrderQuickAddressViewContainer,
    path: 'quick_address',
  },
  ShipView: {
    screen: ShipViewContainer,
    path: 'ship',
  },
  OrderShipListView: {
    screen: OrderShipListViewContainer,
    path: 'ship',
  },
  AddCoverSheet: {
    screen: AddCoverSheetViewContainer,
  },
  AddCoverSheetNote: {
    screen: AddCoverSheetNoteContainer,
  },
  OrderPrivateAndPublicNotes: {
    screen: OrderPrivateAndPublicNotesContainer,
  },
  PaymentPTTTransactions: {
    screen: PaymentPTTTransactionsContainer,
  },
  MarketplaceOrderView,
  PrintShippingLabelView,
  ProductWarehousesPicker,
  PGSelectorView: {
    screen: PGSelectorViewContainer,
    path: 'pgs',
  },
  OrderCopyTemplateView: {
    screen: WebOrderCopyTemplateView,
    path: 'order_copy_template',
  },
  ChannelListViewContainer: {
    screen: ChannelListViewContainer,
    path: 'channel_list',
    // navigationOptions: {
    //   isAlwaysActive: true,
    // },
  },
  ChannelView: {
    screen: ChannelViewContainer,
    path: 'channel',
  },
  CCustomerList: {
    screen: CCustomerListViewContainer,
    path: 'customer',
  },
  CCustomerEditingView: {
    screen: CCustomerEditingViewContainer,
    path: 'customer',
  },
  ContactView: {
    screen: ContactViewContainer,
    path: 'contact',
  },
  StoreSettingXShippingView: {
    screen: StoreSettingXShippingViewContainer,
    path: 'StoreSettingXShippingView',
  },
  StoreSettingXShippingJAndTView: {
    screen: StoreSettingXShippingJAndTViewContainer,
    path: 'StoreSettingXShippingJAndTView',
  },
  JAndTConnectView: {
    screen: JAntTConnectViewContainer,
    path: 'JAndTConnectView',
  },
  CreateXShippingAddressView: {
    screen: AddressXShippingViewContainer,
    path: 'CreateXShippingAddressView',
  },
  StoreSettingXShippingKerryView: {
    screen: StoreSettingXShippingKerryViewContainer,
    path: 'StoreSettingXShippingKerryView',
  },
  StoreSettingXShippingFlashView: {
    screen: StoreSettingXShippingFlashViewContainer,
    path: 'StoreSettingXShippingFlashView',
  },
  StoreSettingXShippingEMSView: {
    screen: StoreSettingXShippingEMSViewContainer,
    path: 'StoreSettingXShippingEMSView',
  },
  StoreSettingXShippingEcoPostView: {
    screen: StoreSettingXShippingEcoPostViewContainer,
    path: 'StoreSettingXShippingEcoPostView',
  },
  StoreSettingXShippingDHLView: {
    screen: StoreSettingXShippingDHLViewContainer,
    path: 'StoreSettingXShippingDHLView',
  },
  StoreSettingXShippingNinjaVanView: {
    screen: StoreSettingXShippingNinjaVanViewContainer,
    path: 'StoreSettingXShippingNinjaVanView',
  },
  StoreSettingXShippingSCGView: {
    screen: StoreSettingXShippingSCGViewContainer,
    path: 'StoreSettingXShippingSCGView',
  },
  StoreSettingXShippingBestView: {
    screen: StoreSettingXShippingBestViewContainer,
    path: 'StoreSettingXShippingBestView',
  },
  CustomCreateOrderView: {
    screen: CustomCreateOrderViewContainer,
    path: 'CustomCreateOrderView',
  },
  CustomOrderView: {
    screen: CustomOrderViewContainer,
    path: 'CustomOrderView',
  },
  MultiParcelView: {
    screen: MultiParcelViewContainer,
    path: 'MultiParcelView',
  },
  SelectedProductMultiParcelView: {
    screen: SelectedProductMultiParcelViewContainer,
    path: 'SelectedProductMultiParcelView',
  },
  PGSelectorOnly: {
    screen: PGSelectorOnlyViewContainer,
    path: 'PGSelectorOnly',
  },
  // for web usage only
  // EmptyView: {
  //   // screen: DashboardViewContainer,
  //   screen: () => <div />,
  //   path: 'empty',
  // },

  // From AuthSwitchNavigator
  ResellerRegisterView: {
    screen: ResellerRegisterView,
    path: 'register_reseller',
  },
  ResellerImportView: {
    screen: ResellerImportViewContainer,
    path: 'import_reseller',
  },

  // From importExcelStackNavigator
  importExcelListView: {
    screen: importExcelListViewContainer,
    path: 'import_excel',
    navigationOptions: {
      isAlwaysActive: true,
    },
  },
  ImportWarehouseExcelFromShipjung: {
    screen: ImportWarehouseExcelFromShipjungContainer,
    path: 'import_excel_warehouse_excel_from_shipjung',
  },
  importEditProductWarehouseBySKU: {
    screen: importEditProductWarehouseBySKUContainer,
    path: 'import_edit_product_warehouse_by_sku',
  },
  importReduceProductWarehouseBySKU: {
    screen: importReduceProductWarehouseBySKUContainer,
    path: 'import_reduce_product_warehouse_by_sku',
  },
  importAddProductWarehouseBySKU: {
    screen: importAddProductWarehouseBySKUContainer,
    path: 'import_add_product_warehouse_by_sku',
  },
  importProductQTYView: {
    screen: importProductQTYViewContainer,
    path: 'import_excel_product_qty',
  },
  ImportProductBarcodeView: {
    screen: ImportProductBarcodeViewContainer,
    path: 'import_excel_product_barcode',
  },
  ImportAddProductView: {
    screen: ImportAddProductViewContainer,
    path: 'import_excel_add_product',
  },
  ImportExcelCODView: {
    screen: ImportExcelCODViewContainer,
    path: 'import_excel_cod',
  },
  ImportAddCustomerView: {
    screen: ImportAddCustomerViewContainer,
    path: 'import_excel_add_user_cintact',
  },
  ImportExcelCreateOrderView: {
    screen: ImportExcelCreateOrderViewContainer,
    path: 'import_excel_create_order',
  },

  // From ProductGroupStackNavigator
  ProductGroupListView: {
    screen: ProductGroupListViewContainer,
    path: 'product_group_list_view',
    navigationOptions: {
      isAlwaysActive: true,
    },
  },
  ProductGroupView: {
    screen: ProductGroupViewContainer,
    path: 'product_group',
  },

  // From QuickShipViewStackNavigator
  QuickShipView: {
    screen: QuickShipViewContainer,
    path: 'main',
    navigationOptions: {
      isAlwaysActive: true,
    },
  },

  // From ReportListStackNavigator
  ReportListView: {
    screen: ReportListViewContainer,
    path: 'resellers',
    navigationOptions: {
      isAlwaysActive: true,
    },
  },
  ReportStockView: {
    screen: ReportStockViewContainer,
    path: 'report_stock_view',
  },
  ReportWarehouseAdjustment: {
    screen: ReportWarehouseAdjustmentContainer,
    path: 'report_warehouse_adjstmen',
  },
  CompleteSalesReportView: {
    screen: CompleteSalesReportViewContainer,
    path: 'complete_sakes_report',
  },
  ReportRequisitionView: {
    screen: ReportRequisitionViewContainer,
    path: 'report_requisition_view',
  },
  ReportShippingView: {
    screen: ReportShippingViewContainer,
    path: 'report_shipping',
  },
  ReportSalesByChannelView: {
    screen: ReportSalesByChannelViewContainer,
    path: 'seles_by_channel',
  },
  ReportResellerView: {
    screen: ReportResellerViewContainer,
    path: 'reseller',
  },
  ReportHelperSales: {
    screen: ReportHelperSalesContainer,
    path: 'helper_sales',
  },
  ReportPayment: {
    screen: ReportPaymentContainer,
    path: 'payment_sales',
  },
  ReportImportPeak: {
    screen: ReportPeakViewContainer,
    path: 'report_peak',
  },
  DailySalesReportView: {
    screen: DailySalesReportViewContainer,
    path: 'DailySalesReportView',
  },
  ReportProductDailySales: {
    screen: ReportProductDailySalesContainer,
    path: 'ReportProductDailySales',
  },

  // From ResellerApprovalListStackNavigator
  ResellerApprovalListView: {
    screen: ResellerApprovalListViewContainer,
    path: 'resellers',
    navigationOptions: {
      isAlwaysActive: true,
    },
  },
  CustomerViewContainer: {
    screen: CustomerViewContainer,
    path: 'customer',
  },
  CustomerEditProfileViewContainer: {
    screen: CustomerEditProfileViewContainer,
    path: 'customer_edit_profile',
  },

  // From ResellerListStackNavigator
  ResellerListView: {
    screen: ResellerListViewContainer,
    path: 'resellers',
    navigationOptions: {
      isAlwaysActive: true,
    },
  },

  // From SettingViewStackNavigator
  SettingView: {
    screen: SettingViewContainer,
    path: 'setting_view',
    navigationOptions: {
      isAlwaysActive: true,
    },
  },
  About: {
    screen: AboutViewContainer,
    path: 'about_view',
  },
  SettingResetPasswordView: {
    screen: SettingResetPasswordViewContainer,
    path: 'setting_reset_password',
  },
  SettingEmailViewContainer: {
    screen: SettingEmailViewContainer,
    path: 'setting_email',
  },
  ProfileView: {
    screen: ProfileViewContainer,
    path: 'profile',
  },

  // From StoreOrdersDonutViewStackNavigator
  StoreOrdersDonutListView: {
    screen: StoreOrdersDonutListViewContainer,
    path: 'main',
    navigationOptions: {
      isAlwaysActive: true,
    },
  },

  // From StoreSettingStackNavigator
  StoreSettingList: {
    screen: StoreSettingListViewContainer,
    path: 'main',
    navigationOptions: {
      isAlwaysActive: true,
    },
  },
  StoreSettingAllPrintingShipping: {
    screen: StoreSettingAllPrintingShippingLabelContainer,
    path: 'StoreSettingAllPrintingShipping',
  },
  StoreSettingPaperang: {
    screen: StoreSettingPaperangContainer,
    path: 'StoreSettingPaperang',
  },
  StoreSettingPDFShippingLabel: {
    screen: StoreSettingPDFShippingLabelContainer,
    path: 'StoreSettingPDFShippingLabel',
  },
  StoreSettingStickertContainer: {
    screen: StoreSettingStickerViewContainer,
    path: 'StoreSettingStickertContainer',
  },
  StoreSettingBillView: {
    screen: StoreSettingBillViewContainer,
    path: 'StoreSettingBillView',
  },
  StoreSettingPrintingBarcode: {
    screen: StoreSettingPrintingBarcodeContainer,
    path: 'StoreSettingPrintingBarcode',
  },
  AssistantGroupList: {
    screen: AssistantGroupListViewContainer,
    path: 'AssistantGroupList',
  },
  AssistantMember: {
    screen: AssistantMemberViewContainer,
    path: 'AssistantMember',
  },
  AssistantGroup: {
    screen: AssistantGroupViewContainer,
    path: 'AssistantGroup',
  },
  CategoryList: {
    screen: CategoryListViewContainer,
    path: 'CategoryList',
  },
  CategoryProperty: {
    screen: CategoryPropertyViewContainer,
    path: 'CategoryProperty',
  },
  StoreSettingGeneral: {
    screen: StoreSettingGeneralContainer,
    path: 'StoreSettingGeneral',
  },
  StoreSettingPeakView: {
    screen: StoreSettingPeakViewContainer,
    path: 'StoreSettingPeakView',
  },
  AddressListView: {
    screen: AddressListViewContainer,
    path: 'AddressListView',
  },
  AddressView: {
    screen: AddressViewContainer,
    path: 'AddressView',
  },
  BankEditView: {
    screen: BankEditViewContainer,
    path: 'BankEditView',
  },

  // From UserGroupStackNavigator
  UserGroupListView: {
    screen: UserGroupListViewContainer,
    path: 'user_group_list_view',
    navigationOptions: {
      isAlwaysActive: true,
    },
  },
  UserGroupView: {
    screen: UserGroupViewContainer,
    path: 'user_group',
  },
  ProductGroupSettingForUserGroup: {
    screen: ProductGroupSettingForUserGroupContainer,
    path: 'pg_setting_for_user_group',
  },
  UserGroupResellerTabs: {
    screen: UserGroupResellerTabsContainer,
    path: 'ug_tab',
  },
  ResellerChangeGroupView: {
    screen: ResellerChangeGroupViewContainer,
    path: 'ug_tab',
  },
  ResellerManageView: {
    screen: ResellerManageViewContainer,
    path: 'ResellerManageView',
  },
  SubscriptionView: {
    screen: SubscriptionViewContainer,
    path: 'SubscriptionView',
    navigationOptions: {
      isAlwaysActive: true,
    },
  },
  SelectPackageView: {
    screen: SelectPackageViewContainer,
    path: 'SelectPackageView',
    // navigationOptions: {
    //   isAlwaysActive: true,
    // },
  },
  PaymentSubscriptionPackageView: {
    screen: PaymentSubscriptionPackageViewContainer,
    path: 'PaymentSubscriptionPackageView',
  },
  SelectAddOnPackageView: {
    screen: SelectAddOnPackageViewContainer,
    path: 'SelectAddOnPackageView',
  },
  StoreWebsiteListView: {
    screen: StoreWebsiteListViewContainer,
    path: 'StoreWebsiteListView',
  },
  StoreWebsiteView: {
    screen: StoreWebsiteViewContainer,
    path: 'StoreWebsiteView',
  },
  SalePageView: {
    screen: SalePageViewContainer,
    path: 'SalePageView',
  },
  SalePageSettingWebView: {
    screen: SalePageSettingWebViewContainer,
    path: 'SalePageSettingWebView',
  },
  SalePageEditInfoView: {
    screen: SalePageEditInfoViewContainer,
    path: 'SalePageEditInfoView',
  },
  SalePagePixelView: {
    screen: SalePagePixelViewContainer,
    path: 'SalePagePixelView',
  },
  EditorView: {
    screen: TextEditorViewContainer,
    path: 'EditorView',
  },
  CreateStoreWebsiteView: {
    screen: CreateStoreWebsiteViewContainer,
    path: 'CreateStoreWebsiteView',
  },
  CreateSalePageView: {
    screen: CreateSalePageViewContainer,
    path: 'CreateSalePageView',
  },
  StoreWebsiteSettingPaymentView: {
    screen: StoreWebsiteSettingPaymentViewContainer,
    path: 'StoreWebsiteSettingPaymentView',
  },
  StoreWebsiteSettingOptionsOrderView: {
    screen: StoreWebsiteSettingOptionsOrderViewContainer,
    path: 'StoreWebsiteSettingOptionsOrderView',
  },
  StoreWebsiteCreateOptionsOrderView: {
    screen: StoreWebsiteCreateOptionsOrderViewContainer,
    path: 'StoreWebsiteCreateOptionsOrderView',
  },
  SalePageSelectProductView: {
    screen: SalePageSelectProductViewContainer,
    path: 'SalePageSelectProductView',
  },
  SalePageAddItemWebView: {
    screen: SalePageAddItemWebViewContainer,
    path: 'SalePageAddItemWebView',
  },
  EditCountdownTimeView: {
    screen: EditCountdownTimeView,
    path: 'EditCountdownTimeView',
  },
  StoreWebsiteSelectedOptionModeView: {
    screen: StoreWebsiteSelectedOptionModeViewContainer,
    path: 'StoreWebsiteSelectedOptionModeView',
  },
  ERPListView: {
    screen: ERPListViewContainer,
    path: 'ERPListView',
  },
  ERPCreateView: {
    screen: ERPCreateViewContainer,
    path: 'ERPCreateView',
  },
  ERPCreatePeakView: {
    screen: ERPCreatePeakViewContainer,
    path: 'ERPCreatePeakView',
  },
  ERPDetailView: {
    screen: ERPDetailViewContainer,
    path: 'ERPDetailView',
  },
  ERPPaymentAccountView: {
    screen: ERPPaymentAccountViewContainer,
    path: 'ERPPaymentAccountView',
  },
  ERPEditPaymentAccountView: {
    screen: ERPEditPaymentAccountViewContainer,
    path: 'ERPEditPaymentAccountView',
  },
  ERPChannelContact: {
    screen: ERPChannelContactContainer,
    path: 'ERPChannelContact',
  },
  ErpOrderView: {
    screen: ErpOrderViewContainer,
    path: 'ErpOrderView',
  },
  InAppPurchaseView: {
    screen: InAppPurchaseViewContainer,
    path: 'InAppPurchaseView',
  },
  InAppPurchasePerPackageView: {
    screen: InAppPurchasePerPackageViewContainer,
    path: 'InAppPurchasePerPackageView',
  },
  ERPShippingSetting: {
    screen: ERPShippingSettingContainer,
    path: 'ERPShippingSetting',
  },
  ActivePackageDetailView: {
    screen: ActivePackageDetailContainer,
    path: 'ActivePackageDetailView',
  },
}
