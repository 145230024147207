import _ from 'lodash'
import {
  IXSegmentOption,
  IOrdersSortType,
  IOrdersSortBy,
  IOrdersSortOptions,
  IShippingType,
  IShippingViewSetting,
  IProductListSortOptions,
  IProductListSortType,
  IProductListSortBy,
  IErpDocTypeOption,
} from 'x/types'
// import { PrintOptionKey } from '../index'

// const Constants: IConstants = {
const Constants = {
  APP_NAME: 'XSelly',
  APP_NAMES: {
    xselly: 'XSelly',
    somsai: 'SOMSAI',
  },
  APP_CONFIG_NAMES: {
    XSELLY: 'xselly',
    SOMSAI: 'somsai',
  },

  MODE: {
    VIEW: 'VIEW',
    EDIT: 'EDIT',
    CREATE: 'CREATE',
  },

  // PRINT_OPTIONS: {
  //   BILL_PAPERANG: 'BILL_PAPERANG',
  //   PAPERANG: 'PAPERANG',
  // },
  PRINT_OPTIONS: {
    PAPERANG: 'PAPERANG',
    A4_PDF: 'A4_PDF',
    STICKER_PDF: 'STICKER_PDF',

    BILL_PAPERANG: 'BILL_PAPERANG',

    REPORT_PRODUCT_DAILY_SALES: 'REPORT_PRODUCT_DAILY_SALES',
  } as const,

  AUTHENTICATION_STORAGE_KEY: 'AppState:Authentication',
  FACEBOOK_AUTH_STORAGE_KEY: 'AppState:FBAuth',

  BADGE_OVERFLOW_COUNT: 999,
  BADGE_TYPE_BG_APP_MAIN: 'nbBadgeTypeBGAppMain',
  BADGE_TYPE_TEXT_APP_MAIN: 'nbBadgeTypeTextAppMain',
  //  SCREEN_W: Dimensions.get('window').width,
  //  SCREEN_H: Dimensions.get('window').height,
  //   IS_IOS: Platform.OS === 'ios',
  SERVER_DATE_FORMAT: 'YYYY-MM-DD',
  SERVER_DATETIME_FORMAT: 'YYYY-MM-DD HH:mm:ss',
  SERVER_DATETIME_FORMAT_WITH_MILLISECONDS: 'YYYY-MM-DD HH:mm:ss.SSS',
  SERVER_DATETIME_FORMAT_WITH_MICROSECONDS: 'YYYY-MM-DD HH:mm:ss.SSSSSS',
  // DATE_FORMAT: 'D MMM YYYY, HH:mm',
  DATE_FORMAT: 'D MMM YY, HH:mm',
  DISPLAY_DATE_FORMAT: 'D/MM/YYYY',
  DISPLAY_DATETIME_FORMAT: 'D/MM/YYYY HH:mm',

  ACTION_NO_OP: 'no-op',
  ORDER_TAB_KEY_SEP: '_',
  ORDER_KEY_MY_TASKS: 'myTasks',
  ORDER_KEY_CUST_TASKS: 'custTasks',
  ORDER_KEY_RESELLER_TASKS: 'resellerTasks',
  ORDER_KEY_SELLER_TASKS: 'sellerTasks',
  ORDER_KEY_DONE_TASKS: 'doneTasks',
  ORDER_KEY_CONFIRM_ORDER: 'confirmOrder',
  ORDER_KEY_PAY: 'pay',
  ORDER_KEY_AUTO_COMPLETE: 'autoComplete',
  ORDER_KEY_CONFIRM_GETTING_PAID: 'confirmGettingPaid',
  ORDER_KEY_SHIP: 'ship',
  ORDER_KEY_SHIPPED: 'shipped',
  ORDER_KEY_AUTO_COMPLETED: 'autoCompleted',
  ORDER_KEY_CANCELLED: 'cancelled',
  ORDER_KEY_PROCESS: 'process',
  STORES_MODE_MY_VIEW: 'MY_VIEW',
  STORES_MODE_MY_PULL: 'MY_PULL',
  STORES_MODE_SELLER_VIEW: 'SELLER_VIEW',
  STORES_MODE_SELLER_PULL_PRODUCT: 'SELLER_PULL_PRODUCT',

  KEYBOARD_PERSIST_TAB_MODE: 'handled',

  // Adrress
  ADDRESS_LIST_MODE_KEYS: {
    CUSTOMER_PICK: 'CUSTOMER_PICK',
    CUSTOMER_PICK_CHANGE: 'CUSTOMER_PICK_CHANGE',
    CUSTOMER_EDIT: 'CUSTOMER_EDIT',
    STORE_PICK: 'STORE_PICK',
    STORE_EDIT: 'STORE_EDIT',
    STORE_PICK_CHANGE: 'STORE_PICK_CHANGE',
  },

  ADDRESS_MODE_KEYS: {
    CUSTOMER_EDIT: 'CUSTOMER_EDIT',
    CUSTOMER_ADD: 'CUSTOMER_ADD',
    STORE_EDIT: 'STORE_EDIT',
    STORE_ADD: 'STORE_ADD',
  },

  ADDRESS_BRANCH_TYPE_OPTIONS: [
    { key: 'not_specified', label: 'ไม่ระบุ', value: null },
    { key: 'headquarter', label: 'สำนักงานใหญ่', value: 'h' },
    { key: 'branch', label: 'สาขา', value: 'b' },
  ],
  ADDRESS_LEGAL_ENTITY_LABELS: ['ไม่ระบุ', 'เลขประจำตัวประชาชน', 'เลขประจำตัวผู้เสียภาษี'],
  ADDRESS_LEGAL_ENTITY_TYPE_OPTIONS: ['ไม่ระบุ', 'บุคคลธรรมดา', 'นิติบุคคล'],
  ADDRESS_LEGAL_ENTITY_TYPE_INDIVIDUAL_MORE_OPTIONS: [
    { id: 1, key: 'individual', label: 'บุคคลธรรมดา' },
    { id: 3, key: 'general_partnership', label: 'ห้างหุ้นส่วนสามัญ' },
    { id: 4, key: 'store', label: 'ร้านค้า' },
    { id: 5, key: 'group_of_people', label: 'คณะบุคคล' },
  ],
  ADDRESS_LEGAL_ENTITY_TYPE_CORPORATION_MORE_OPTIONS: [
    { id: 2, key: 'legal_entity', label: 'นิติบุคคล', isHidden: true },
    { id: 11, key: 'limited_company', label: 'บริษัทจำกัด' },
    { id: 12, key: 'public_limited_company', label: 'บริษัทมหาชนจำกัด' },
    { id: 13, key: 'limited_partnership', label: 'ห้างหุ้นส่วนจำกัด' },
    { id: 14, key: 'fundation', label: 'มูลนิธิ' },
    { id: 15, key: 'association', label: 'สมาคม' },
    { id: 16, key: 'joint_venture', label: 'กิจการร่วมค้า' },
    { id: 19, key: 'other', label: 'อื่นๆ' },
  ],

  // สำหรับ Contact View
  CONTACT_VIEW_KEYS: [
    { label: 'ชื่อ', key: 'first_name', inputType: 'text', required: true },
    { label: 'นามสกุล', key: 'last_name', inputType: 'text' },
    { label: 'โทรศัพท์', key: 'phone1', inputType: 'text' },
    // { label: 'ชื่อที่ใช้แสดง' , key: 'display_name', inputType: 'text' },
    // { label: 'เพศ' , key: 'gender', inputType: 'radio', options: [
    //   { label: 'ชาย', value: 1 },
    //   { label: 'หญิง', value: 2 },
    //   { label: 'ไม่ระบุ', value: 3 },
    // ] },
    // { label: 'LINE ID' , key: 'line_id', inputType: 'text' },
    { label: 'อีเมล', key: 'contact_email', inputType: 'text' },
  ],

  CONTACT_EDITABLE_ADDRESS_KEYS: [
    // { label: 'เป็นที่อยู่หลัก' , key: 'is_primary', inputType: 'switch', options: { sideIcon: 'md-home' } },
    { label: 'ชื่อ*', key: 'name', inputType: 'text', required: true },
    { label: 'ที่อยู่*', key: 'address1', inputType: 'text', required: true },
    { label: 'ที่อยู่ (เพิ่มเติม)', key: 'address2', inputType: 'text', required: false },
    { label: 'แขวง/ตำบล', key: 'sub_district', inputType: 'text', required: true },
    { label: 'เขต/อำเภอ*', key: 'district', inputType: 'text', required: true },
    { label: 'จังหวัด*', key: 'province', inputType: 'text', required: true },
    { label: 'ไปรษณีย์*', key: 'postal_code', inputType: 'text', required: true },
    { label: 'โทรศัพท์*', key: 'telephone', inputType: 'text', required: false },
    { label: 'อีเมล', key: 'email', inputType: 'text', required: false },
  ],

  CONTACT_CREATE_KEYS: [
    { label: 'ชื่อ', key: 'first_name', inputType: 'text', required: true },
    { label: 'นามสกุล', key: 'last_name', inputType: 'text' },

    // สอดไส้ address
    { label: 'ที่อยู่', key: 'address1', inputType: 'text', required: true },
    { label: 'ที่อยู่ (เพิ่มเติม)', key: 'address2', inputType: 'text' },
    { label: 'แขวง/ตำบล', key: 'sub_district', inputType: 'text', required: true },
    { label: 'เขต/อำเภอ', key: 'district', inputType: 'text', required: true },
    { label: 'จังหวัด', key: 'province', inputType: 'text', required: true },
    { label: 'ไปรษณีย์', key: 'postal_code', inputType: 'text', required: true },
    // { label: 'โทรศัพท์' , key: 'telephone', inputType: 'text' },

    { label: 'โทรศัพท์', key: 'phone1', inputType: 'text' },
    // { label: 'ชื่อที่ใช้แสดง' , key: 'display_name', inputType: 'text' },
    // { label: 'เพศ' , key: 'gender', inputType: 'radio', options: [
    //   { label: 'ชาย', value: 1 },
    //   { label: 'หญิง', value: 2 },
    //   { label: 'ไม่ระบุ', value: 3 },
    // ] },
    // { label: 'LINE ID' , key: 'line_id', inputType: 'text' },
    { label: 'อีเมล', key: 'contact_email', inputType: 'text' },
  ],

  CONTACT_LIST_MODE_KEYS: {
    VIEW_ONLY: 'VIEW_ONLY',
    VIEW_EDIT: 'VIEW_EDIT',
    EDIT: 'EDIT',
    PICK_CONTACT: 'PICK_CONTACT',
    PICK_ADDRESS: 'PICK_ADDRESS',
    PICK_EDIT_ADDRESS: 'PICK_EDIT_ADDRESS',
  },

  CONTACT_LIST_STATE_KEYS: {
    CUSTOMER: { list: 'customerList', group: 'customerListGroup' },
    RESELLER: { list: 'resellerList', group: 'resellerListGroup' },
  },

  CONTACT_VIEW_MODE: {
    VIEW_EDIT: 'VIEW_EDIT',
    VIEW_ONLY: 'VIEW_ONLY',
  },

  CONTACT_EDITING_MODE: {
    CUSTOMER_ADD: 'CUSTOMER_ADD',
    CUSTOMER_EDIT: 'CUSTOMER_EDIT',
  },

  CONTACT_ACTOR_KEYS: {
    CUSTOMER: 'CUSTOMER',
    RESELLER: 'RESELLER',
    SELLER: 'SELLER',
  },

  DEFAULT_KEYS: {
    RATED_AT: 'userRatedAt',
    ORDER_OPENED_COUNT: 'orderOpenedCount',
    ORDER_OPENED_NEXT_TRIGGER: 'orderOpenedTrigger',
  },

  DEFAULT_SHIPPING_VIEW_SETTING: {
    scanOrderInEveryMyStores: false,
    skipCheckShippingProducts: true,
    skipScanTrackingNumber: false,
  } as IShippingViewSetting,
  DEFAULT_SHIPPING_VIEW_SETTING_FROM_ORDER_VIEW: {
    scanOrderInEveryMyStores: false,
    skipCheckShippingProducts: false,
    skipScanTrackingNumber: false,
  } as IShippingViewSetting,

  NOTIFICATION_COMMON_KEYS: ['title', 'body'],
  NOTIFICATION_NAV_CASE_KEY: 'nav',
  NOTIFICATION_PARAMS_KEYS_INTEGER: [
    'store_id',
    'order_id',
    'product_id',
    'payment_account_id',
    'profile_id',
    'seller_store_id',
    'rejected_store_id',
    'reject_store_id',
    'ug_id',
  ],
  NOTIFICATION_PARAMS_KEYS_STRING: ['m', 'refresh_ss'],
  NOTIFICATION_PARAMS_KEYS_OBJECT: ['order_ids'],
  // NOTIFICATION_NAV_CASE: {
  //   od: {
  //     navAction: params => NavActions.navToOrderDetail(params),
  //     requiredParams: ['store_id', 'order_id'],
  //   },
  //   pm: {
  //     navAction: params => NavActions.navToReceivedPaymentList(params),
  //     requiredParams: ['store_id', 'payment_account_id'],
  //   },
  //   mpd: {
  //     navAction: params => NavActions.navToProductViewSelf(params),
  //     requiredParams: ['store_id', 'product_id'],
  //   },
  //   spd: {
  //     navAction: params => NavActions.navToProductViewSelf(params),
  //     requiredParams: ['store_id', 'product_id'],
  //   },
  // },

  ONBOARD_MISSING_FIELDS: {
    EMAIL: 'email',
    STORE: 'store',
    USERNAME: 'username',
  },

  // สำหรับหน้า Order View
  ORDER_VIEW_MODE: {
    CREATE: 'CREATE',
    EDIT: 'EDIT',
    VIEW_ONLY: 'VIEW_ONLY',
    VIEW_EDIT: 'VIEW_EDIT',
  },

  TEL_NUM_REQUIRED_FOR_SHIPPING_TYPE_IDS: [
    1, // normal
    2, // register
    70, // register COD
    3, // ems
    71, // ecopost
    72, // ecopost_cod
    4, // kerry
    6,
    25,
    35,
    36,
    15,
    23,
    24, // kerry_cod
    31,
    33, // kerry_pickup
    34,
    14,
    29,
    22,
    30,
    12,
    38,
    39,
    7,
    26,
    41,
    8,
    27,
    13,
    28,
    46,
    47,
    48,
    49, // best_pickup
    60, // kerry dropoff
    68, // makesend
    69, // makesend cod
    73, // zto
    74, // zto cod
    81, // tp
    82, // tp cod
  ],

  ALLOW_MISSING_RECEIVER_ADDR_FOR_SHIPPING_TYPE_IDS: [
    5, // other
    40, // store_front
    45, // buyer_pickup
  ],

  COD_SHIPPING_TYPE_IDS: [24, 25, 26, 27, 28, 29, 30, 31, 36, 37, 39, 43, 48, 57, 59, 65, 69, 70, 72, 74, 76, 82],

  ORDER_VIEW_SHIPPING_TYPE_ITEMS: [
    { index: 0, right: 'arrow-forward', value: 1, key: 'normal', tel_required: false },
    { index: 1, right: 'arrow-forward', value: 2, key: 'register', tel_required: true },
    { index: 2, right: 'arrow-forward', value: 70, key: 'register_cod', tel_required: true },
    { index: 3, right: 'arrow-forward', value: 3, key: 'ems', tel_required: true },
    { index: 4, right: 'arrow-forward', value: 37, key: 'ems_cod', tel_required: true },
    { index: 5, right: 'arrow-forward', value: 71, key: 'ecopost', tel_required: true },
    { index: 6, right: 'arrow-forward', value: 72, key: 'ecopost_cod', tel_required: true },
    { index: 7, right: 'arrow-forward', value: 6, key: 'alpha', tel_required: true },
    { index: 8, right: 'arrow-forward', value: 25, key: 'alpha_cod', tel_required: true },
    { index: 9, right: 'arrow-forward', value: 78, key: 'aramex', tel_required: false },
    { index: 10, right: 'arrow-forward', value: 35, key: 'best', tel_required: true },
    { index: 11, right: 'arrow-forward', value: 36, key: 'best_cod', tel_required: true },
    { index: 12, right: 'arrow-forward', value: 49, key: 'best_pickup', tel_required: true },
    { index: 13, right: 'arrow-forward', value: 15, key: 'businessidea', tel_required: true },
    { index: 14, right: 'arrow-forward', value: 23, key: 'cj', tel_required: true },
    { index: 15, right: 'arrow-forward', value: 31, key: 'cj_cod', tel_required: true },
    { index: 16, right: 'arrow-forward', value: 34, key: 'ctt', tel_required: true },
    { index: 17, right: 'arrow-forward', value: 14, key: 'dhl', tel_required: true },
    { index: 18, right: 'arrow-forward', value: 29, key: 'dhl_cod', tel_required: true },
    { index: 19, right: 'arrow-forward', value: 46, key: 'dhl_pickup', tel_required: true },
    { index: 20, right: 'arrow-forward', value: 63, key: 'dhl_bulky', tel_required: true },
    { index: 21, right: 'arrow-forward', value: 77, key: 'ems_world', tel_required: false },
    { index: 22, right: 'arrow-forward', value: 79, key: 'fedex', tel_required: false },
    { index: 23, right: 'arrow-forward', value: 22, key: 'flash', tel_required: true },
    { index: 24, right: 'arrow-forward', value: 30, key: 'flash_cod', tel_required: true },
    { index: 25, right: 'arrow-forward', value: 50, key: 'flash_pickup', tel_required: true },
    { index: 26, right: 'arrow-forward', value: 75, key: 'fuze', tel_required: true },
    { index: 27, right: 'arrow-forward', value: 76, key: 'fuze_cod', tel_required: true },
    { index: 28, right: 'arrow-forward', value: 51, key: 'grab', tel_required: false },
    { index: 29, right: 'arrow-forward', value: 52, key: 'get', tel_required: false },
    { index: 30, right: 'arrow-forward', value: 61, key: 'inter', tel_required: true },
    { index: 31, right: 'arrow-forward', value: 12, key: 'ittransport', tel_required: true },
    { index: 32, right: 'arrow-forward', value: 38, key: 'jt', tel_required: true },
    { index: 33, right: 'arrow-forward', value: 39, key: 'jt_cod', tel_required: true },
    { index: 34, right: 'arrow-forward', value: 44, key: 'jt_pickup', tel_required: true },
    { index: 35, right: 'arrow-forward', value: 65, key: 'jt_cod_pickup', tel_required: true },
    { index: 36, right: 'arrow-forward', value: 4, key: 'kerry', tel_required: true },
    { index: 37, right: 'arrow-forward', value: 24, key: 'kerry_cod', tel_required: true },
    { index: 38, right: 'arrow-forward', value: 33, key: 'kerry_pickup', tel_required: true },
    { index: 39, right: 'arrow-forward', value: 60, key: 'kerry_dropoff', tel_required: true },
    { index: 40, right: 'arrow-forward', value: 53, key: 'lalamove', tel_required: false },
    { index: 41, right: 'arrow-forward', value: 56, key: 'lex', tel_required: false },
    { index: 42, right: 'arrow-forward', value: 57, key: 'lex_cod', tel_required: false },
    { index: 43, right: 'arrow-forward', value: 54, key: 'lineman', tel_required: false },
    { index: 44, right: 'arrow-forward', value: 68, key: 'makesend', tel_required: true },
    { index: 45, right: 'arrow-forward', value: 69, key: 'makesend_cod', tel_required: true },
    { index: 46, right: 'arrow-forward', value: 7, key: 'ninja', tel_required: true },
    { index: 47, right: 'arrow-forward', value: 26, key: 'ninja_cod', tel_required: true },
    { index: 48, right: 'arrow-forward', value: 41, key: 'ninja_pickup', tel_required: true },
    { index: 49, right: 'arrow-forward', value: 8, key: 'nim', tel_required: true },
    { index: 50, right: 'arrow-forward', value: 27, key: 'nim_cod', tel_required: true },
    { index: 51, right: 'arrow-forward', value: 21, key: 'pl', tel_required: false },
    { index: 52, right: 'arrow-forward', value: 13, key: 'scg', tel_required: true },
    { index: 53, right: 'arrow-forward', value: 28, key: 'scg_cod', tel_required: true },
    { index: 54, right: 'arrow-forward', value: 58, key: 'shopee_xpress', tel_required: false },
    { index: 55, right: 'arrow-forward', value: 59, key: 'shopee_xpress_cod', tel_required: false },
    { index: 56, right: 'arrow-forward', value: 64, key: 'shopee_xpress_bulky', tel_required: false },
    { index: 57, right: 'arrow-forward', value: 66, key: 'shopee_std_delivery', tel_required: false },
    { index: 58, right: 'arrow-forward', value: 67, key: 'shopee_std_delivery_bulky', tel_required: false },
    { index: 59, right: 'arrow-forward', value: 55, key: 'skootar', tel_required: false },
    { index: 60, right: 'arrow-forward', value: 47, key: 'speedd', tel_required: true },
    { index: 61, right: 'arrow-forward', value: 48, key: 'speedd_cod', tel_required: true },
    { index: 62, right: 'arrow-forward', value: 62, key: 'springbox', tel_required: true },
    { index: 63, right: 'arrow-forward', value: 81, key: 'tp', tel_required: true },
    { index: 64, right: 'arrow-forward', value: 82, key: 'tp_cod', tel_required: true },
    { index: 65, right: 'arrow-forward', value: 42, key: 'true', tel_required: true },
    { index: 66, right: 'arrow-forward', value: 43, key: 'true_cod', tel_required: true },
    { index: 67, right: 'arrow-forward', value: 80, key: 'ups', tel_required: false },
    { index: 68, right: 'arrow-forward', value: 73, key: 'zto', tel_required: true },
    { index: 69, right: 'arrow-forward', value: 74, key: 'zto_cod', tel_required: true },
    // { index: 30, right: 'arrow-forward', value: 32, key: 'sunthai', tel_required: false },
    { index: 70, right: 'arrow-forward', value: 11, key: 'changbin', tel_required: false },
    { index: 71, right: 'arrow-forward', value: 19, key: 'tanamai', tel_required: false },
    { index: 72, right: 'arrow-forward', value: 17, key: 'baramee', tel_required: false },
    { index: 73, right: 'arrow-forward', value: 10, key: 'bs', tel_required: false },
    { index: 74, right: 'arrow-forward', value: 9, key: 'blueandwhite', tel_required: false },
    { index: 75, right: 'arrow-forward', value: 20, key: 'piyawat', tel_required: false },
    { index: 76, right: 'arrow-forward', value: 16, key: 'mango', tel_required: false },
    { index: 77, right: 'arrow-forward', value: 18, key: 'ksd', tel_required: false },
    { index: 78, right: 'arrow-forward', value: 40, key: 'store_front', tel_required: false },
    { index: 79, right: 'arrow-forward', value: 45, key: 'buyer_pickup', tel_required: false },
    { index: 80, right: 'arrow-forward', value: 5, key: 'other', tel_required: false },
  ] as IShippingType[],

  ORDER_VIEW_CHANNEL_ITEMS: [
    { index: 0, right: 'arrow-forward', value: 0, key: 'noselected' },
    { index: 1, right: 'arrow-forward', value: 3, key: 'resellers_not_yet_use_app' },
    { index: 2, right: 'arrow-forward', value: 7, key: 'line' },
    { index: 3, right: 'arrow-forward', value: 12, key: 'line_add' },
    { index: 4, right: 'arrow-forward', value: 19, key: 'line_shopping' },
    { index: 5, right: 'arrow-forward', value: 28, key: 'line_man' },
    { index: 6, right: 'arrow-forward', value: 6, key: 'facebook' },
    { index: 7, right: 'arrow-forward', value: 13, key: 'facebook_page' },
    { index: 8, right: 'arrow-forward', value: 11, key: 'instagram' },
    { index: 9, right: 'arrow-forward', value: 17, key: 'twitter' },
    { index: 10, right: 'arrow-forward', value: 8, key: 'shopee' },
    { index: 11, right: 'arrow-forward', value: 29, key: 'shopee_food' },
    { index: 12, right: 'arrow-forward', value: 9, key: 'lazada' },
    { index: 13, right: 'arrow-forward', value: 26, key: 'tiktok' },
    { index: 14, right: 'arrow-forward', value: 34, key: 'shopify' },
    { index: 15, right: 'arrow-forward', value: 35, key: 'vrich' },
    { index: 16, right: 'arrow-forward', value: 24, key: 'branch' },
    { index: 17, right: 'arrow-forward', value: 25, key: 'consignment' },
    { index: 18, right: 'arrow-forward', value: 14, key: 'dealer' },
    { index: 19, right: 'arrow-forward', value: 15, key: 'distributor' },
    { index: 20, right: 'arrow-forward', value: 20, key: 'friend' },
    { index: 21, right: 'arrow-forward', value: 21, key: 'influencer' },
    { index: 22, right: 'arrow-forward', value: 22, key: 'marketing' },
    { index: 23, right: 'arrow-forward', value: 27, key: 'event' },
    { index: 24, right: 'arrow-forward', value: 16, key: 'repurchase' },
    { index: 25, right: 'arrow-forward', value: 5, key: 'shop' },
    { index: 26, right: 'arrow-forward', value: 18, key: 'telesales' },
    { index: 27, right: 'arrow-forward', value: 23, key: 'website' },
    { index: 28, right: 'arrow-forward', value: 10, key: 'eleven_street' },
    { index: 29, right: 'arrow-forward', value: 30, key: 'foodpanda' },
    { index: 30, right: 'arrow-forward', value: 31, key: 'grab' },
    { index: 31, right: 'arrow-forward', value: 32, key: 'robinhood' },
    { index: 32, right: 'arrow-forward', value: 33, key: 'japanesepartner' },
    { index: 33, right: 'arrow-forward', value: 36, key: 'claim' },
    { index: 34, right: 'arrow-forward', value: 1, key: 'others' },
    // { index: 10, right: 'arrow-forward', value: 2, key: 'reseller_guest' },
    // { index: 11, right: 'arrow-forward', value: 4, key: 'app_ecommerce_web' },
  ],
  ERP_DOC_TYPE_OPTIONS: [
    { id: 11, key: 'quotation', label: 'ใบเสนอราคา' },
    { id: 12, key: 'invoice', label: 'ใบแจ้งหนี้', is_tax_invoice_available: true },
    { id: 13, key: 'tax_invoice', label: 'tax_invoice' },
    { id: 14, key: 'receipt', label: 'ใบเสร็จรับเงิน', is_tax_invoice_available: true },
    { id: 15, key: 'cash_invoice', label: 'cash_invoice' },
    { id: 16, key: 'debit_note', label: 'debit_note' },
    { id: 17, key: 'credit_note', label: 'credit_note' },
    { id: 21, key: 'purchase_order', label: 'purchase_order' },
    { id: 22, key: 'receiving_inventory', label: 'receiving_inventory' },
    { id: 31, key: 'expense', label: 'expense' },
    { id: 32, key: 'withhilding_tax', label: 'withhilding_tax' },
  ] as IErpDocTypeOption[],

  PREF_DEFAULT_ORDER_COPY_TEMPLATE:
    'ออเดอร์ [order_number]' +
    '\nรวมยอดชำระ [total_amount]' +
    '\nสร้างเมื่อ [order_create_datetime]' +
    '\n--------------' +
    '\n[product_and_price_items]' +
    '\n--------------' +
    '\nจำนวนรวมสินค้าทั้งออเดอร์: [product_total_qty]' +
    '\n[product_amount_label]' +
    '\n[shipping_amount_label]' +
    '\n[etc_amount_label]' +
    '\n[order_discount_amount_label]' +
    '\n[member_discount_amount_label]' +
    '\n[cod_amount_label]' +
    '\n--------------' +
    '\nรูปแบบจัดส่ง: [shipping_type]' +
    '\nชื่อที่อยู่ผู้รับ: [receiver_address]' +
    '\nดูรายละเอียดและสถานะการจัดส่งได้ที่ [order_link]',
  PREF_ORDER_TEMPLATE_VARIABLES: [
    {
      label: '#เลขออเดอร์',
      tag: '[order_number]',
    },
    {
      label: 'ชื่อที่อยู่ผู้รับ',
      tag: '[receiver_address]',
    },
    {
      label: 'รูปแบบจัดส่ง',
      tag: '[shipping_type]',
    },
    {
      label: 'รายการสินค้า',
      tag: '[product_items]',
    },
    {
      label: 'รายการสินค้า ฿x',
      tag: '[product_and_price_items]',
    },
    {
      label: 'ยอดชำระ',
      tag: '[total_amount]',
    },
    {
      label: 'วันเวลาที่สร้างออเดอร์',
      tag: '[order_create_datetime]',
    },
    {
      label: 'จำนวนรวมสินค้าทั้งออเดอร์',
      tag: '[product_total_qty]',
    },
    {
      label: 'รวมค่าสินค้า ฿x',
      tag: '[product_amount_label]',
    },
    {
      label: 'ค่าส่ง ฿x',
      tag: '[shipping_amount_label]',
      isOptional: true,
    },
    {
      label: 'ค่าอื่นๆ ฿x',
      tag: '[etc_amount_label]',
      isOptional: true,
    },
    {
      label: 'ส่วนลด ฿x',
      tag: '[order_discount_amount_label]',
      isOptional: true,
    },
    {
      label: 'ส่วนลดสมาชิก ฿x',
      tag: '[member_discount_amount_label]',
      isOptional: true,
    },
    {
      label: 'ค่าธรรมเนียมเก็บเงินปลายทาง ฿x',
      tag: '[cod_amount_label]',
      isOptional: true,
    },
    {
      label: 'ลิงก์ออเดอร์',
      tag: '[order_link]',
    },
  ],

  XSHIPPING_STATUS: [
    { value: 0, key: 'requesting_tracking' },
    { value: 1011, key: 'requesting_tracking' },
    { value: 1012, key: 'requesting_tracking' },
    { value: 1013, key: 'requesting_tracking' },
    { value: 1014, key: 'requesting_tracking' },
    { value: 1015, key: 'awaiting_order_pack' },
    { value: 1016, key: 'processing_order_pack' },
    { value: 1018, key: 'requesting_tracking' },
    { value: 1019, key: 'request_after_edit' },
    { value: 1801, key: 'cancel_tracking' },
    { value: 1802, key: 'cancel_tracking' },
    { value: 1809, key: 'error_occur_please_call_support' },

    { value: 2010, key: 'have_tracking' },
    { value: 2011, key: 'picking' },
    { value: 2012, key: 'on_the_way_to_drop_off' },
    { value: 2013, key: 'out_for_delivery' },

    { value: 2014, key: 'picked_up' },
    { value: 2015, key: 'arrival' },
    { value: 2016, key: 'mkp_shipping_label_received' },

    { value: 2019, key: 'departed' },
    { value: 2110, key: 'processing_at_warehouse' },
    { value: 2111, key: 'ready_to_ship_at_warehouse' },
    { value: 2112, key: 'waiting_for_courier_pickup' },

    { value: 2200, key: 'awaiting_to_sourcing' },
    { value: 2020, key: 'in_transit' },
    { value: 2230, key: 'cross_border_processing' },
    { value: 2231, key: 'received_at_origin_facility' },
    { value: 2232, key: 'departed_at_origin_facility' },
    { value: 2233, key: 'cross_border_transit' },
    { value: 2234, key: 'customs_onhold' },
    { value: 2235, key: 'customs_cleared' },
    { value: 2236, key: 'received_at_desitination_facility' },
    { value: 2237, key: 'departed_at_desitination_facility' },
    { value: 2238, key: 'received_at_sorting_facility' },

    { value: 2400, key: 're_delivery' },
    { value: 2401, key: 'overnight' },
    { value: 2402, key: 'failed_to_delivery_due_to_invalid_phone_number' },
    { value: 2403, key: 'failed_to_delivery_due_to_recipient_absent' },
    { value: 2404, key: 'delivering_to_new_address' },
    { value: 2405, key: 'hold_in_station' },
    { value: 2406, key: 'in_the_payment' },
    { value: 2407, key: 'failed_to_deliver' },
    { value: 2408, key: 'awaiting_confirmation_to_redelivery' },

    { value: 2410, key: 'awaiting_to_return' },
    { value: 2420, key: 'return_processing' },
    { value: 2430, key: 'return_approved' },

    { value: 2500, key: 'returning' },
    { value: 2510, key: 'out_of_return' },
    { value: 2511, key: 'return_refused' },
    { value: 2520, key: 'awaiting_receive_at_facility' },
    { value: 2521, key: 'awaiting_cancellation' },

    { value: 2530, key: 'return_to_orgin_facility' },
    { value: 2540, key: 'refused_by_courier' },
    { value: 2550, key: 'awaiting_return_to_warehouse' },
    { value: 2551, key: 'received_return_at_warehouse' },
    { value: 2552, key: 'inspected_return_at_warehouse' },

    { value: 2610, key: 'returned_at_orgin_facility' },
    { value: 2701, key: 'pending_for_audit' },

    { value: 2800, key: 'arrived_at_departure_transit_facility' },
    { value: 2801, key: 'arrived_at_arrival_transit_facility' },
    { value: 2802, key: 'departed_from_departure_transit_facility' },
    { value: 2803, key: 'export_cancelled' },
    { value: 2804, key: 'import_cancelled' },
    { value: 2805, key: 'handed_over_to_airline' },
    { value: 2806, key: 'received_by_airline' },

    { value: 4032, key: 'invalid_address_pickup' },
    { value: 4033, key: 'invalid_recipient_address' },

    { value: 9001, key: 'delivered' },

    // TO BE DELETED after backend migrate states
    { value: 9002, key: 'fulfilled' },

    { value: 9003, key: 'destroy_parcel' },

    // TO BE DELETED after backend migrate states
    { value: 9009, key: 'cod_amount_has_been_successfully' },

    // 9099 is TO BE DELETED after a full roll out of 9999. Shall be able to delete it after Mar 1, 2023
    { value: 9099, key: 'unknown_state' },

    { value: 9999, key: 'unknown_state' },

    // Final: Return parcel statuses
    { value: 9101, key: 'returned_at_orgin_facility' },
    { value: 9102, key: 'returned_at_warehouse' },

    // Final: COD Reconcilation statuses
    { value: 9201, key: 'cod_reconciled_by_courier_balance_transfer' },
    { value: 9202, key: 'cod_reconciled_as_the_package_is_received' },

    // ERROR
    { value: 8001, key: 'cancelled_by_sellers' },
    { value: 8002, key: 'cancelled_by_operator' },
    { value: 8003, key: 'cancelled_by_partner' },
    { value: 8004, key: 'cancelled_by_system_3rd_party' },
    { value: 8005, key: 'cancelled_by_app_backend' },
    { value: 8006, key: 'cancelled_at_warehouse' },
    { value: 8007, key: 'cancelled_by_lost_item' },

    { value: 4020, key: 'not_enough_money' },
    { value: 4030, key: 'out_of_delivery_area' },
    { value: 4031, key: 'receiver_name_must_be_not_empty' },
    { value: 4080, key: 'thaipost_branch_close' },

    { value: 4089, key: 'remote_server_error' },
    { value: 4099, key: 'unknown_error' },
    { value: 4202, key: 'pickup_failed' },
    { value: 4212, key: 'out_of_stock' },

    { value: 'X_4010', key: 'request_repeatedly' },
    { value: 'X_4011', key: 'cent_get_tracking_value' },
    { value: 'X_4012', key: 'cent_cancel_tracking_code' },

    { value: 'X_4013', key: 'customer_id_customerid_cannot_be_empty' },
    { value: 'X_4014', key: 'logistics_order_number_txlogisticid_cannot_be_empty' },
    { value: 'X_4015', key: 'order_type_cannot_be_empty' },
    { value: 'X_4016', key: 'service_type_servicetype_cannot_be_empty' },

    { value: 'X_4101', key: 'invalid_address' },
    { value: 'X_4102', key: 'invalid_address' },
    { value: 'X_4103', key: 'invalid_address' },
    { value: 'X_4104', key: 'invalid_zipcode' },
    { value: 'X_4105', key: 'invalid_phone_number' },

    { value: 'X_4106', key: 'invalid_matchterm' },
    { value: 'X_4107', key: 'invalid_xshipping' },
    { value: 'X_4108', key: 'invalid_system_account' },
    { value: 'X_4109', key: 'invalid_order' },
    { value: 'X_4110', key: 'invalid_order_state' },

    { value: 'X_4111', key: 'invalid_shipment' },
    { value: 'X_4112', key: 'invalid_shipping_type' },
    { value: 'X_4113', key: 'bad_network' },
    { value: 'X_4114', key: 'system_address' },
    { value: 'X_4115', key: 'no_undeleted_product_to_ship' },
    { value: 'X_4116', key: 'error_occur_please_call_support' },
    { value: 'X_4117', key: 'Maximum_number_of_retries_to_fetch_tracking_code_reached' },
    { value: 'X_4118', key: 'order_has_been_canceled' },
    { value: 'X_4119', key: 'request_repeatedly_auto_new_request' },
    { value: 'X_4120', key: 'sender_address_invalid_matchterm' },
    { value: 'X_4121', key: 'product_has_been_deleted' },

    { value: 'X_4201', key: 'the_sender_name_sender_name_cannot_be_empty' },
    { value: 'X_4202', key: 'send_phone_sender_mobile_can_not_be_empty' },
    { value: 'X_4203', key: 'sender_sender_city_cannot_be_empty' },
    { value: 'X_4204', key: 'shipment_county_sender_area_can_not_be_empty' },
    { value: 'X_4205', key: 'the_sender_address_sender_address_cannot_be_empty' },
    { value: 'X_4206', key: 'the_recipient_name_receiver_name_cannot_be_empty' },
    { value: 'X_4207', key: 'receiving_mobile_phone_receiver_mobile_can_not_be_empty' },
    { value: 'X_4208', key: 'receiver_receiver_city_cannot_be_empty' },
    { value: 'X_4209', key: 'the_recipient_area_county_area_cannot_be_empty' },
    { value: 'X_4210', key: 'receiver_address_address_cannot_be_empty' },
    { value: 'X_4211', key: 'order_creation_time_createordertime_cannot_be_empty' },
    { value: 'X_4212', key: 'logistics_company_pick_up_start_time_sendstarttime_can_not_be_empty' },
    { value: 'X_4213', key: 'logistics_company_pick_up_end_time_sendendtime_can_not_be_empty' },
    { value: 'X_4214', key: 'payment_method_paytype_cannot_be_empty' },
    { value: 'X_4215', key: 'total_number_of_totalquantity_cannot_be_empty' },
    { value: 'X_4216', key: 'the_collection_value_of_the_item_value_cannot_be_empty' },
    { value: 'X_4217', key: 'chinese_name_items_itemname_can_not_be_empty' },
    { value: 'X_4218', key: 'english_name_items_englishName_cannot_be_empty' },
    { value: 'X_4219', key: 'number_of_items_number_cannot_be_empty' },
    { value: 'X_4220', key: 'declared_value_idr_items_itemvalue_cannot_be_empty' },
    { value: 'X_4221', key: 'item_url_items_itemurl_cannot_be_empty' },
    { value: 'X_4222', key: 'sender_sender_city_does_not_exist' },
    { value: 'X_4223', key: 'sender_area_county_sender_area_does_not_exist' },
    { value: 'X_4224', key: 'the_recipient_area_county_area_does_not_exist' },
    { value: 'X_4225', key: 'the_receiving_city_province_does_not_exist' },
    { value: 'X_4226', key: 'items_error_items_list_length_exceeds_10' },
    { value: 'X_4227', key: 'order_operation_type_cannot_be_empty' },

    { value: 'X_4228', key: 'incorrect_shipping_address' },
    { value: 'X_4229', key: 'incorrect_address' },
    { value: 'X_4230', key: 'weight_cannot_be_empty' },
    { value: 'X_4231', key: 'Order_type_operation_is_illegal' },
    { value: 'X_4232', key: 'order_number_cannot_be_empty' },
    { value: 'X_4233', key: 'the_waybill_number_cannot_be_empty' },
    { value: 'X_4234', key: 'operational_waybill_does_not_match_urrent_e_commerce' },
    { value: 'X_4235', key: 'cancel_order_failed_record_not_found' },
    { value: 'X_4236', key: 'save_failed' },
    { value: 'X_4237', key: 'employee_number_does_not_exist' },
    { value: 'X_4238', key: 'order_file_creation_failed' },
    { value: 'X_4239', key: 'remark_longer_than_400' },
    { value: 'X_4240', key: 'weight_over_50kg' },
    { value: 'X_4241', key: 'incorrect_length_width_and_height' },
    { value: 'X_4242', key: 'api_restriction_does_not_pass' },
    { value: 'X_4243', key: 'cod_exceeds_the_maximum_limit' },
    { value: 'X_4244', key: 'shipping_information_cannot_be_empty' },
    { value: 'X_4245', key: 'receiving_information_cannot_be_empty' },
    { value: 'X_4246', key: 'the_face_sheet_type_cannot_be_empty' },
    { value: 'X_4247', key: 'registration_information_already_exists' },
    { value: 'X_4248', key: 'customer_id_is_too_long' },
    { value: 'X_4249', key: 'customer_information_does_not_exist' },
    { value: 'X_4250', key: 'registration_failed' },
    { value: 'X_4251', key: 'the_outlet_information_does_not_exist' },
    { value: 'X_4252', key: 'customer_quote_is_not_maintained' },
    { value: 'X_4253', key: 'customerId_cannot_be_empty' },
    { value: 'X_4254', key: 'name_cannot_be_empty' },
    { value: 'X_4255', key: 'phone_cannot_be_empty' },
    { value: 'X_4256', key: 'address_cannot_be_empty' },
    { value: 'X_4257', key: 'postcode_cannot_be_empty' },
    { value: 'X_4258', key: 'source_cannot_be_empty' },
    { value: 'X_4259', key: 'invalid_sender_address' },
    { value: 'X_4260', key: 'invalid_sender_zipcode' },
    { value: 'X_4261', key: 'receiver_address_longer_than_250' },

    { value: 'X_4401', key: 'incomplete_courier_registration' },
    { value: 'X_4402', key: 'jt_vip_account_not_yet_linked' },
    { value: 'X_4403', key: 'jt_vip_account_not_yet_linked' },
    { value: 'X_4404', key: 'jt_not_yet_registered_on_xselly' },

    { value: 'X_4262', key: 'please_contact_the_team_due_to_failed_signature' },
    { value: 'X_4263', key: 'this_order_could_not_be_found_in_the_system' },
    { value: 'X_4264', key: 'cant_find_this_warehouse' },
    { value: 'X_4265', key: 'not_yet_opened_COD_service' },
    { value: 'X_4266', key: 'cash_on_delivery_COD_must_be_greater_than_0' },
    { value: 'X_4267', key: 'sender_information_and_warehouse_no_can_not_be_blank' },
    { value: 'X_4268', key: 'pno_and_mchid_numbers_dont_match' },
    { value: 'X_4269', key: 'amount_of_products_that_need_insurance_must_be_greater_than_0' },
    { value: 'X_4270', key: 'user_id_was_not_found_in_the_transport_system' },
    { value: 'X_4271', key: 'please_correct_the_recipient_name_to_be_no_more_than_50_characters' },
    { value: 'X_4272', key: 'please_correct_the_sender_name_to_be_no_more_than_50_characters' },
    { value: 'X_4273', key: 'incorrect_sender_subdistrict' },
    { value: 'X_4274', key: 'sender_subdistrict_is_required' },
    { value: 'X_4275', key: 'refid_missing' },
    { value: 'X_4276', key: 'json_unmarshall_error' },
    { value: 'X_4277', key: 'cod_payment_account_required' },
    { value: 'X_4278', key: 'wrong_cod_payment_account_config' },
    { value: 'X_4279', key: 'unsupported_bank_for_cod_payment_account' },
    { value: 'X_4280', key: 'bank_account_name_required' },
    { value: 'X_4281', key: 'receiver_courier_zipcode_mismatch' },
    { value: 'X_4282', key: 'incorrect_recipient_subdistrict' },
    { value: 'X_4283', key: 'recipient_subdistrict_is_required' },
    { value: 'X_4284', key: 'sender_courier_zipcode_mismatch' },
    { value: 'X_4285', key: 'cod_amount_must_be_a_round_number' },
    { value: 'HTTP_502', key: 'bad_gateway' },
  ],

  XSHIPPING_STATUS_ERR: [
    1019,
    4020,
    4030,
    4031,
    4032,
    4033,
    4080,
    4089,
    4099,
    4202,
    4212,
    8001,
    8002,
    8003,
    8004,
    8005,
    8006,
    8007,
    'HTTP_400',
    'HTTP_403',
    'HTTP_404',
    'HTTP_405',
    'HTTP_406',
    'HTTP_401',
    'HTTP_410',
    'HTTP_418',
    'HTTP_429',
    'HTTP_500',
    'HTTP_502',
    'HTTP_503',
    'X_4010',
    'X_4011',
    'X_4012',

    'X_4013',
    'X_4014',
    'X_4015',
    'X_4016',

    'X_4099',
    'X_4101',
    'X_4102',
    'X_4103',
    'X_4104',
    'X_4105',
    'X_4106',
    'X_4107',
    'X_4108',
    'X_4109',
    'X_4110',
    'X_4111',
    'X_4112',
    'X_4113',
    'X_4114',
    'X_4115',
    'X_4116',
    'X_4117',

    'X_4201',
    'X_4202',
    'X_4203',
    'X_4204',
    'X_4205',
    'X_4206',
    'X_4207',
    'X_4208',
    'X_4209',
    'X_4210',
    'X_4211',
    'X_4212',
    'X_4213',
    'X_4214',
    'X_4215',
    'X_4216',
    'X_4217',
    'X_4218',
    'X_4219',
    'X_4220',
    'X_4221',
    'X_4222',
    'X_4223',
    'X_4224',
    'X_4225',
    'X_4226',
    'X_4227',

    'X_4228',
    'X_4229',
    'X_4230',
    'X_4231',
    'X_4232',
    'X_4233',
    'X_4234',
    'X_4235',
    'X_4236',
    'X_4237',
    'X_4238',
    'X_4239',
    'X_4240',
    'X_4241',
    'X_4242',
    'X_4243',
    'X_4244',
    'X_4245',
    'X_4246',
    'X_4247',
    'X_4248',
    'X_4249',
    'X_4250',
    'X_4250',
    'X_4251',
    'X_4252',
    'X_4253',
    'X_4254',
    'X_4255',
    'X_4256',
    'X_4257',
    'X_4258',
    'X_4259',
    'X_4260',
    'X_4401',

    'X_4262',
    'X_4263',
    'X_4264',
    'X_4265',
    'X_4266',
    'X_4267',
    'X_4268',
    'X_4269',
    'X_4270',
    'X_4271',
    'X_4272',
  ],
  XSHIPPING_STATUS_IN_PROCESS: [1011, 1012, 1013, 1014, 1015, 1016],
  XSHIPPING_STATUS_HAS_TRACKING_NO: [
    2010, 2011, 2012, 2013, 2019, 2110, 2111, 2200, 2230, 2231, 2232, 2233, 2234, 2235, 2236, 2237, 2410, 2420, 2430, 2500, 2510, 2511,
    2520, 2521, 2530, 2540, 2550, 2551, 2552, 2610, 2701,
  ],
  XSHIPPING_STATUS_TASKS: {
    XSHIPPING_HAS_TRACKING_NO: 'xShipping_HasTrackingNo',
    XSHIPPING_REQUESTING: 'xShipping_Requesting',
    XSHIPPING_PANDING_REQUEST_AUTO: 'xShipping_PendingRequestAuto',
    XSHIPPING_pENDING_REQUEST_MANUAL: 'xShipping_PendingRequestManual',
    XSHIPPING_REQUEST_FAILES: 'xShipping_RequestFailed',
    xShipping_Cancelling: 'xShipping_Cancelling',
    xShipping_CancelFailed: 'xShipping_CancelFailed',
  },
  xshippingStatus: {
    xShipping_HasTrackingNo: 'xShipping_HasTrackingNo',
    xShipping_Requesting: 'xShipping_Requesting',
    xShipping_PendingRequestAuto: 'xShipping_PendingRequestAuto',
    xShipping_PendingRequestManual: 'xShipping_PendingRequestManual',
    xShipping_RequestFailed: 'xShipping_RequestFailed',
    xShipping_Cancelling: 'xShipping_Cancelling',
    xShipping_CancelFailed: 'xShipping_CancelFailed',
  },

  // เลขที่บ่งบอกว่า ถ้าเกิดสร้างออเดอร์เริ่มต้นถึง 5 ออเดอร์ App จะขอ Rate this app!!
  RATE_ORDER_COUNT_BEGIN: 5,
  RATE_AGAIN_IN_DAYS: 90,
  RATE_APP_OPTIONS: {
    AppleAppID: '1279749848',
    GooglePackageName: 'com.xselly',
    OtherAndroidURL: 'https://play.google.com/store/apps/details?id=com.xselly',
    preferredAndroidMarket: 1, // 1 === AndroidMarket.Google
    // preferredAndroidMarket: AndroidMarket.Google,
    fallbackPlatformURL: 'https://play.google.com/store/apps/details?id=com.xselly',
    preferInApp: true,
  },

  STATE_SAVE_PREFIX: {
    SHIPPING_VIEW_FROM_ORDER_VIEW: 'FROM_ORDER_VIEW::',
  },
  STATE_SAVE_KEYS: {
    SHIPPING_VIEW_SETTING: 'SHIPPING_VIEW_SETTING',
    SHIPPING_ORDER_CARD: 'SHIPPING_ORDER_CARD',
    SHIPPING_TRACKING_NUMBER_CARD: 'SHIPPING_TRACKING_NUMBER_CARD',
    SHIPPING_PRODUCTS_CARD: 'SHIPPING_PRODUCTS_CARD',
    SHIPPING_CONFIRMATION_CARD: 'SHIPPING_CONFIRMATION_CARD',
    BARCODE_SCANNER_MODAL: 'BARCODE_SCANNER_MODAL',
    BARCODE_SCANNER_SHIPPING_ORDER: 'BARCODE_SCANNER_SHIPPING_ORDER',
    BARCODE_SCANNER_SHIPPING_PRODUCTS: 'BARCODE_SCANNER_SHIPPING_PRODUCTS',
    BARCODE_SCANNER_SHIPPING_TRACKING_NUMBER: 'BARCODE_SCANNER_SHIPPING_TRACKING_NUMBER',
    BARCODE_SCANNER_SHIPPING_CONFIRMATION: 'BARCODE_SCANNER_SHIPPING_CONFIRMATION',
    LATEST_PICKED_STORE_ID: 'LATEST_PICKED_STORE_ID',
    SELECTED_STORE_ORDERS_DATES_OPTION_KEY: 'SELECTED_STORE_ORDERS_DATES_OPTION_KEY',
  },

  SUBSCRIPTION_MAIN_PACKAGE_ITEMS: [
    { index: 0, value: 1, key: 'free' },
    { index: 1, value: 2, key: 'bronze' },
    { index: 2, value: 3, key: 'silver' },
    { index: 3, value: 4, key: 'gold' },
    { index: 4, value: 5, key: 'platinum' },
    { index: 5, value: 999, key: 'trial' },
  ],
  SUBSCRIPTION_PACKAGE: {
    FREE: 1,
    BRONZE: 2,
    SILVER: 3,
    GOLD: 4,
    PLATINUM: 5,
    TRIAL: 999,
  },
  SUBSCRIPTION_SETTINGS: {
    orderWarningInPercentage: 0.7,
    orderDangerInPercentage: 0.9,
    expireWarningInDay: 14,
    expireDangerInDay: 7,
  },

  SHIPPING_TYPE_IDS: {
    REGISTER: 2,
    EMS: 3,
    OTHER: 5,
  },

  // ORDER_VIEW_SHIPPING_TYPE_ITEMS: [
  //   { index: 0, left: 'md-car', value: 1, key: 'normal' },
  //   { index: 1, left: 'md-car', value: 2, key: 'register' },
  //   { index: 2, left: 'md-car', value: 3, key: 'ems' },
  //   { index: 3, left: 'md-car', value: 4, key: 'kerry' },
  //   { index: 4, left: 'md-car', value: 5, key: 'other' },
  // ],

  ORDER_TYPE_0: 0, // reserved for create mode
  ORDER_TYPE_1: 1,
  ORDER_TYPE_2: 2,
  ORDER_TYPE_3: 3,
  ORDER_VIEW_KEY_SUMMARY_ITEMS: [
    { key: 'amountProducts', backendKey: null, label: 'มูลค่ารวมสินค้า', readonly: true },
    { key: 'amountDiscount', backendKey: 'discount', label: 'ส่วนลด (-)', readonly: false },
    { key: 'amountShipping', backendKey: 'shipping_cost', label: 'ค่าจัดส่ง (+)', readonly: false },
    { key: 'amountEtc', backendKey: 'etc_cost', label: 'ค่าอื่นๆ (+)', readonly: false },
    { key: 'amountTotal', backendKey: null, label: 'รวมสุทธิ', readonly: true },
  ],
  ORDER_EDITABLE_KEY: 'editable',
  ORDER_EDITABLE: {
    RECEIVER_ADDR: 'receiverAddr',
  },
  ORDER_VIEW_KEY_SUMMARY_MINUS_KEYS: ['amountDiscount'],

  ORDER_VIEW_OPERATION_ITEMS: {
    CONFIRM: { key: 'confirm', label: 'ยืนยัน' },
    PAY: { key: 'pay', label: 'ชำระ' },
    SHIP: { key: 'ship', label: 'จัดส่ง' },
    CANCEL: { key: 'cancel', label: 'ยกเลิกใบสั่งซื้อ' },
  },

  ORDER_VIEW_PRODUCT_CARD_DESCS: {
    CREATE: [
      // { key: 'cost', editable: false, isMoney: true },
      { key: 'price', editable: false, isMoney: true },
      { key: 'qty', editable: false },
      // { key: 'sum', icon: 'ios-albums', }, manual
    ],
    EDIT: [
      // { key: 'cost', isMoney: true },
      { key: 'price', editable: false, isMoney: true },
      { key: 'qty', editable: true },
      // { key: 'sum', icon: 'ios-albums', }, manual
    ],
    VIEW_ONLY: [
      // { key: 'cost', isMoney: true },
      { key: 'price', editable: false, isMoney: true },
      { key: 'qty', editable: true },
      // { key: 'sum', icon: 'ios-albums', }, manual
    ],
    VIEW_EDIT: [
      // { key: 'cost', isMoney: true },
      { key: 'price', editable: false, isMoney: true },
      { key: 'qty', editable: true },
      // { key: 'sum', icon: 'ios-albums', }, manual
    ],
  },

  ORDER_VIEW_PRODUCT_CARD_ADDITION_INFO: {
    CREATE: [{ key: 'available_qty' }, { key: 'cost', isMoney: true }],
    EDIT: [{ key: 'cost', isMoney: true }],
    VIEW_ONLY: [{ key: 'cost', isMoney: true }],
    VIEW_EDIT: [{ key: 'cost', isMoney: true }],
  },

  PRODUCT_LIST_SORT_BY_OPTION_VALUES: ['name', 'created_at', 'updated_at'] as const,
  PRODUCT_LIST_SORT_BY_OPTIONS: [
    { label: 'ชื่อ', value: 'name' },
    { label: 'วันที่สร้าง', value: 'created_at' },
    { label: 'วันที่อัพเดท', value: 'updated_at' },
  ] as Array<IXSegmentOption<IProductListSortBy>>,
  PRODUCT_LIST_SORT_TYPE_OPTION_VALUES: ['desc', 'asc'] as const,
  PRODUCT_LIST_SORT_TYPE_OPTIONS: [
    { label: 'ใหม่ไปเก่า', value: 'desc' },
    { label: 'เก่าไปใหม่', value: 'asc' },
  ] as Array<IXSegmentOption<IProductListSortType>>,
  PRODUCT_LIST_SORT_TYPE_OPTIONS_BY_NAME: [
    { label: 'Z-A , ฮ-ก', value: 'desc' },
    { label: 'A-Z , ก-ฮ', value: 'asc' },
  ] as Array<IXSegmentOption<IProductListSortType>>,
  DEFAULT_PRODUCT_LIST_SORT_OPTIONS: {
    sortBy: 'updated_at',
    sortType: 'desc',
  } as IProductListSortOptions,

  ORDERS_SORT_BY_OPTION_VALUES: ['id', 'created_at', 'ready_to_ship_date', 'date_delivery', 'expiration_date'] as const,
  ORDERS_SORT_BY_OPTIONS: [
    { label: 'เลขที่ออเดอร์', value: 'id' },
    { label: 'วันสร้างออเดอร์', value: 'created_at' },
    { label: 'วันพร้อมส่ง', value: 'ready_to_ship_date' },
    { label: 'วันกำหนดส่ง', value: 'date_delivery' },
    { label: 'วันยกเลิก ออเดอร์อัตโนมัติเมื่อไม่ชำระ', value: 'expiration_date' },
  ] as Array<IXSegmentOption<IOrdersSortBy>>,
  ORDERS_SORT_TYPE_OPTION_VALUES: ['desc', 'asc'] as const,
  ORDERS_SORT_TYPE_OPTIONS: [
    { label: 'ใหม่ไปเก่า', value: 'desc' },
    { label: 'เก่าไปใหม่', value: 'asc' },
  ] as Array<IXSegmentOption<IOrdersSortType>>,
  DEFAULT_ORDERS_SORT_OPTIONS: {
    sortBy: 'id',
    sortType: 'desc',
  } as IOrdersSortOptions,
  DEFAULT_ORDERS_FETCH_LIMIT: 20,

  ORDER_OPERATION_GET: ({ type, state }) => getOrderOperation({ type, state }),
  ORDER_CANCELABLE_STATES: ['162', '181', '182', '183'],
  // ORDER_STATE_TEXT: ({ type, state }) => p.op.t(`Order.orderStates.${getOrderStateKey({ type, state })}`),
  // ORDER_STATE_KEY: ({ type, state }) => getOrderStateKey({ type, state }),
  ORDER_EDITABLE_TASK_KEY: 'myTasks_confirmOrder', // key ที่สามารถแก้ไขข้อมูลได้

  ORDERS_FETCH_MODE_INIT: 'ORDERS_FETCH_MODE_INIT',
  ORDERS_FETCH_MODE_SUBTAB_INIT: 'ORDERS_FETCH_MODE_SUBTAB_INIT',
  ORDERS_FETCH_MODE_INIT_SINGLE: 'ORDERS_FETCH_MODE_INIT_SINGLE_TAB',
  ORDERS_FETCH_MODE_REFRESH: 'ORDERS_FETCH_MODE_REFRESH',
  ORDERS_FETCH_MODE_MORE: 'ORDERS_FETCH_MODE_MORE',

  ORDERS_VIEW_MODE_STORE: 'ORDERS_VIEW_MODE_STORE',
  ORDERS_VIEW_MODE_PAYMENT: 'ORDERS_VIEW_MODE_PAYMENT',
  ORDERS_VIEW_MODE_SEARCH: 'ORDERS_VIEW_MODE_SEARCH',

  PRODUCTS_FETCH_MODE_INIT: 'PRODUCTS_FETCH_MODE_INIT',
  PRODUCTS_FETCH_MODE_REFRESH: 'PRODUCTS_FETCH_MODE_REFRESH',
  PRODUCTS_FETCH_MODE_MORE: 'PRODUCTS_FETCH_MODE_MORE',
  PRODUCTS_SEARCH_MODE_INIT: 'PRODUCTS_SEARCH_MODE_INIT',
  PRODUCTS_SEARCH_MODE_REFRESH: 'PRODUCTS_SEARCH_MODE_REFRESH',
  PRODUCTS_SEARCH_MODE_MORE: 'PRODUCTS_SEARCH_MODE_MORE',
  // PRODUCTS_FETCH_LIMIT_INIT: 12,
  // PRODUCTS_FETCH_LIMIT_MORE: 24,
  PRODUCTS_FETCH_LIMIT_INIT: 10,
  PRODUCTS_FETCH_LIMIT_MORE: 10,

  // PRODUCT VIEW
  PRODUCT_VIEW_MODE: {
    ADD: 'ADD',
    EDIT_SELF: 'EDIT_SELF',
    EDIT_SELLER: 'EDIT_SELLER',
    // VIEW_ONLY: 'VIEW_ONLY',
    VIEW_SELF: 'VIEW_SELF',
    VIEW_SELLER: 'VIEW_SELLER',
    PULL_MY_PRODUCT_TO_ORDER: 'PULL_MY_PRODUCT_TO_ORDER',
    PULL_SELLER_PRODUCT_TO_ORDER: 'PULL_SELLER_PRODUCT_TO_ORDER',
    PULL_PRODUCT: 'PULL_PRODUCT',
    VIEW_VOLUME_DISCOUNT: 'VIEW_VOLUME_DISCOUNT',
    MKP_MAPPING: 'MKP_MAPPING',
  },
  // PRODUCT VIEW
  CHANNEL_PRODUCT_VIEW_MODE: {
    VIEW: 'VIEW',
    EDIT: 'EDIT',
  },
  PRODUCT_VIEW_INPUTS_EDITABLE_BY_MODE: {
    // input อยู่ตรงนี้
    ADD: { name: true, desc: true, imgs: true, category: true },
    EDIT_SELF: { name: false, desc: true, imgs: true, category: false },
    EDIT_SELLER: { name: false, desc: true, imgs: true, category: false },
    // VIEW_ONLY: { name: false, desc: false, imgs: false , category: false },
    VIEW_SELF: { name: false, desc: false, imgs: false, category: false },
    VIEW_SELLER: { name: false, desc: false, imgs: false, category: false },
    PULL_MY_PRODUCT_TO_ORDER: { name: false, desc: false, imgs: false, category: false },
    PULL_SELLER_PRODUCT_TO_ORDER: { name: false, desc: false, imgs: false, category: false },
    PULL_PRODUCT: { name: true, desc: true, imgs: true, category: false },
  },
  PRODUCT_FETCH_STATUS: {
    OFFSET: 'offset',
    SHOULD_FETCH: 'shouldFetch',
    SHOULD_INIT_LOAD_WITH_LIMIT: 'shouldInitLoadWithLimit',
  },
  // PRODUCT_VIEW_VARIANTS_BY_MODE: { // variant input อยู่ตรงนี้
  //   ADD: { variants: ['name', 'qty', 'cost', 'price'], editable: ['name', 'qty', 'cost', 'price'] },
  //   EDIT_SELF: { variants: ['name', 'qty', 'available_qty', 'cost', 'price'], editable: ['qty', 'cost', 'price'] },
  //   EDIT_SELLER: { variants: ['name', 'available_qty', 'cost', 'price'], editable: ['price'] },
  //   // VIEW_ONLY: { variants: ['name', 'available_qty', 'price'], editable: [] },
  //   VIEW_SELF: { variants: ['name', 'qty', 'available_qty', 'cost', 'price'], editable: [] },
  //   VIEW_SELLER: { variants: ['name', 'available_qty', 'price'], editable: [] },
  //   PULL_MY_PRODUCT_TO_ORDER: { variants: ['name', 'available_qty', 'cost', 'price', 'qty'], editable: ['qty'] },
  //   PULL_PRODUCT: { variants: ['name', 'available_qty', 'cost', 'price'], editable: ['price'] },
  // },
  // PRODUCT_VIEW_VARIANTS_GET: ({ mode }) => getInputVariantsByMode({ mode }),
  // PRODUCT_VIEW_VARIANTS_GET: ({ mode, parent_id, s_use_retail_price, order_use_retail_price }) =>
  //   getProductVariants({ mode, parent_id, s_use_retail_price, order_use_retail_price }),
  PRODUCT_CATEGORY_KEY: 'selectedCategory',
  PRODUCT_CATEGORY_ITEMS: [
    // { id: 1, left: 'ios-basket-outline', label: 'ไม่มีหมวดหมู่', right: 'ios-arrow-forward' },
    // { id: 2, left: 'ios-basket-outline', label: 'แม่และเด็ก', right: 'ios-arrow-forward' },
    // { id: 3, left: 'ios-basket-outline', label: 'มือถือและอุปกรณ์เสริม', right: 'ios-arrow-forward' },
    // { id: 4, left: 'ios-basket-outline', label: 'เสื้อผ้าและแฟชั่น', right: 'ios-arrow-forward' },
    // { id: 5, left: 'ios-basket-outline', label: 'นาฬิกาและแว่นตา', right: 'ios-arrow-forward' },
    // { id: 6, left: 'ios-basket-outline', label: 'คอมพิวเตอร์และแก๊ดเจ๊ด', right: 'ios-arrow-forward' },
    // { id: 7, left: 'ios-baseball-outline', label: 'อุปกรณ์ถ่ายภาพและเครื่องเสียง', right: 'ios-arrow-forward' },
    // { id: 8, left: 'ios-baseball-outline', label: 'กีฬาและกิจกรรมกลางแจ้ง', right: 'ios-arrow-forward' },
    // { id: 9, left: 'ios-baseball-outline', label: 'เกมส์และของสะสม', right: 'ios-arrow-forward' },
    // { id: 10, left: 'ios-baseball-outline', label: 'อาหารและเครื่องดื่ม', right: 'ios-arrow-forward' },
    // { id: 11, left: 'ios-baseball-outline', label: 'สุขภาพและความงาม', right: 'ios-arrow-forward' },
    // { id: 12, left: 'ios-basket-outline', label: 'เครื่องใช้ในบ้าน', right: 'ios-arrow-forward' },
    // { id: 13, left: 'ios-basket-outline', label: 'อื่นๆ', right: 'ios-arrow-forward' },
    { id: 1, label: 'ไม่มีหมวดหมู่', name: 'ไม่มีหมวดหมู่' },
    { id: 2, label: 'แม่และเด็ก', name: 'แม่และเด็ก' },
    { id: 11, label: 'สุขภาพและความงาม', name: 'สุขภาพและความงาม' },
    { id: 14, label: 'กระเป๋าและเครื่องประดับ', name: 'กระเป๋าและเครื่องประดับ' },
    { id: 5, label: 'นาฬิกาและแว่นตา', name: 'นาฬิกาและแว่นตา' },
    { id: 4, label: 'เสื้อผ้าและแฟชั่น', name: 'เสื้อผ้าและแฟชั่น' },
    { id: 3, label: 'มือถือและอุปกรณ์เสริม', name: 'มือถือและอุปกรณ์เสริม' },
    { id: 6, label: 'คอมพิวเตอร์และแก๊ดเจ๊ด', name: 'คอมพิวเตอร์และแก๊ดเจ๊ด' },
    { id: 7, label: 'อุปกรณ์ถ่ายภาพและเครื่องเสียง', name: 'อุปกรณ์ถ่ายภาพและเครื่องเสียง' },
    { id: 12, label: 'เครื่องใช้ในบ้าน', name: 'เครื่องใช้ในบ้าน' },
    { id: 9, label: 'เกมส์และของสะสม', name: 'เกมส์และของสะสม' },
    { id: 10, label: 'อาหารและเครื่องดื่ม', name: 'อาหารและเครื่องดื่ม' },
    { id: 8, label: 'กีฬาและกิจกรรมกลางแจ้ง', name: 'กีฬาและกิจกรรมกลางแจ้ง' },
    { id: 13, label: 'อื่นๆ', name: 'อื่นๆ' },
  ],
  PRODUCT_STOCK: {
    noStock: 'หมดสต็อก',
    lowStock: 'ใกล้หมดสต๊อก',
    noRetailPrice: 'ไม่มีราคาปลีก',
  },
  // BANK
  BANK_INFO: [
    { id: 0, key: 'noselected', name: 'ยังไม่ได้เลือก' },
    { id: 1, key: 'others', name: 'อื่นๆ' },
    { id: 2, key: 'cash', name: 'เงินสด' },
    { id: 24, key: 'promptpay', name: 'พร้อมเพย์' },
    { id: 38, key: 'rabbit_line_pay', name: 'Rabbit LINE Pay' },
    { id: 42, key: 'truemoney', name: 'True Money' },
    { id: 43, key: 'creditcard', name: 'บัตรเครดิต' },
    { id: 3, key: 'kbank', name: 'ธ.กสิกรไทย' },
    { id: 4, key: 'bay', name: 'ธ.กรุงศรีอยุธยา' },
    { id: 5, key: 'ktb', name: 'ธ.กรุงไทย' },
    { id: 6, key: 'scb', name: 'ธ.ไทยพาณิชย์' },
    { id: 7, key: 'bkk', name: 'ธ.กรุงเทพ' },
    { id: 33, key: 'ttb', name: 'ธ.ทีเอ็มบีธนชาต' },
    { id: 9, key: 'gsb', name: 'ธ.ออมสิน' },
    { id: 10, key: 'cimb', name: 'ธ.ซีไอเอ็มบีไทย' },
    { id: 11, key: 'ibank', name: 'ธ.อิสลามแห่งประเทศไทย' },
    { id: 12, key: 'kk', name: 'ธ.เกียรตินาคิน' },
    { id: 13, key: 'tisco', name: 'ธ.ทิสโก้' },
    { id: 15, key: 'uob', name: 'ธ.ยูโอบี' },
    { id: 16, key: 'scnb', name: 'ธ.สแตนดาร์ดชาร์เตอร์ด' },
    { id: 17, key: 'trcbank', name: 'ธ.ไทยเครดิตเพื่อรายย่อย' },
    { id: 18, key: 'lhbank', name: 'ธ.แลนด์ แอนด์ เฮาส์' },
    { id: 19, key: 'smebank', name: 'ธ.พัฒนาวิสาหกิจขนาดกลางและขนาดย่อมแห่งประเทศไทย' },
    { id: 20, key: 'baac', name: 'ธ.เพื่อการเกษตรและสหกรณ์การเกษตร' },
    { id: 21, key: 'exim', name: 'ธ.เพื่อการส่งออกและนำเข้าแห่งประเทศไทย' },
    { id: 22, key: 'ghb', name: 'ธ.อาคารสงเคราะห์' },
    { id: 23, key: 'icbc', name: 'ธ.ไอซีบีซี' },
    { id: 25, key: 'gbpay', name: 'GB PRIME PAY' },
    { id: 26, key: 'cod_deposit', name: 'COD' },
    { id: 27, key: 'scbgateway', name: ' SCB QR Code' },
    { id: 28, key: 'mkp', name: 'ช่องทางขาย' },
    { id: 29, key: 'jt_cod_deposit', name: 'J&T COD' },
    { id: 30, key: 'kerry_cod_deposit', name: 'Kerry COD' },
    { id: 31, key: 'flash_cod_deposit', name: 'Flash COD' },
    { id: 35, key: 'best_cod_deposit', name: 'BEST COD' },
    { id: 36, key: 'ems_cod_deposit', name: 'EMS COD' },
    { id: 37, key: 'ecopost_cod_deposit', name: 'eCo-post COD' },
    { id: 32, key: 'beam', name: 'Beam' },
    { id: 8, key: 'tmb', name: 'ธ.ทหารไทย' },
    { id: 14, key: 'tnc', name: 'ธ.ธนชาต' },
    { id: 41, key: 'citi', name: 'ธ.ซิตี้แบงก์' },
    { id: 39, key: 'hsbc', name: 'ธ.HSBC' },
    { id: 40, key: 'mizuho', name: 'ธ.Mizuho' },

    // { id: 25, key: 'gbpay', name: 'GB PRIME PAY', hidden: true },
    // { id: 26, key: 'cod_deposit', name: 'COD', hidden: true },
    // { id: 27, key: 'scbgateway', name: ' SCB QR Code', hidden: true },
    // { id: 28, key: 'mkp', name: 'ช่องทางขาย', hidden: true },
    // { id: 29, key: 'jt_cod_deposit', name: 'J&T COD', hidden: true },
    // { id: 30, key: 'kerry_cod_deposit', name: 'Kerry COD', hidden: true },
    // { id: 31, key: 'flash_cod_deposit', name: 'Flash COD', hidden: true },
    // { id: 32, key: 'beam', name: 'Beam', hidden: true },
  ],

  BANK_HAVE_ACCOUNT_TYPE: [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 33, 39, 40, 41],

  SYSTEM_BANK_IDS: {
    GBPAY: 25,
    COD: 26, // Shipjung COD
    SCB_QR_CODE: 27,
    MKP: 28,
    J_AND_T_COD: 29,
    KERRY_COD: 30,
    FLASH_COD: 31,
    BEAM: 32,
    BEST_COD: 35,
    EMS_COD: 36,
    ECOPOST_COD: 37,
  },

  SYSTEM_BANK_IDS_ARRAY: [25, 26, 27, 28, 29, 30, 31, 32, 35, 36, 37], // THE VALUES ARE POPULATED at the END of this file

  PRODUCT_GROUP_LIST_MODE: {
    VIEW: 'VIEW',
    SORT: 'SORT',
    ASSIGN_TO_UG: 'ASSIGN_TO_UG',
  },

  PRODUCT_GROUP_VIEW_MODE: {
    ADD: 'ADD',
    VIEW: 'VIEW',
    EDIT: 'EDIT',
  },

  USER_GROUP_LIST_MODE: {
    VIEW: 'VIEW',
    CHANGE_TO_GROUP: 'CHANGE_TO_GROUP',
  },

  USER_GROUP_VIEW_MODE: {
    ADD: 'ADD',
    VIEW: 'VIEW',
    EDIT: 'EDIT',
  },

  // BANK_ACCOUNT_REQUIRE_KEYS: ['name', 'account_number', 'bank_id'],
  // BANK_ACCOUNT_REQUIRE_KEYS: ['name'],

  TIMER_ALMOST_DUE_SECONDS: 10800, // 3 hrs

  // Constants for Components
  PAGEPICKER_VIEWTYPE: { FLAT: 'FlatList', ICON: 'FlatIcon', SECTION: 'SectionList' },
  IMGBLOCK_EMPTY_IMAGE: {
    PROFILE: { bundleName: 'Ionicons', iconName: 'person' },
    // PRODUCT: { bundleName: 'FontAwesome', iconName: 'photo' },
    // DEFAULT: { bundleName: 'FontAwesome', iconName: 'photo' },
  },
  IMG_PLACEHOLDER_URL: 'https://xsf.sgp1.cdn.digitaloceanspaces.com/x/z/i.png',
  IMG_MANAGER_SIZES: {
    // size ในที่นี้หมายถึง max size ถ้าหาก user submit มาไม่ถึง max size ก็จะเป็นตามที่ user submit มา
    // CUSTOMER_PROFILE: { width: 2000, height: 2000 },
    // CUSTOMER_PROFILE_THUMBNAIL: { width: 1000, height: 1000 },
    PAYMENT_SLIP: { width: 640, height: 640 },
    PAYMENT_SLIP_THUMBNAIL: { width: 64, height: 64 },
    COVER_SHEET: { width: 1000, height: 1580 },
    USER_GROUP_LOGO: { width: 128, height: 128 },
    USER_GROUP_LOGO_THUMBNAIL: { width: 64, height: 64 },
    // USER_PROFILE: { width: 384, height: 384 },
    // USER_PROFILE_THUMBNAIL: { width: 128, height: 128 },
  },

  DATERANGE_SELECTOR: {
    OPTION: {
      Today: 'Today',
      Yesterday: 'Yesterday',
      Last7Days: 'Last7Days',
      Last30Days: 'Last30Days',
      Last45Days: 'Last45Days',
      Last60Days: 'Last60Days',
      Last90Days: 'Last90Days',
      ThisWeekStartWithSun: 'ThisWeekStartWithSun',
      ThisWeekStartWithMon: 'ThisWeekStartWithMon',
      LastWeekStartWithSun: 'LastWeekStartWithSun',
      LastWeekStartWithMon: 'LastWeekStartWithMon',
      ThisMonth: 'ThisMonth',
      LastMonth: 'LastMonth',
      Custom: 'Custom',
    } as const,
  },

  ORDER_SUMMARY_ALL: [
    'myTasks_confirmOrder',
    'myTasks_pay',
    'myTasks_confirmGettingPaid',
    'myTasks_ship',
    'custTasks_pay',
    'custTasks_autoComplete',
    'resellerTasks_confirmOrder',
    'resellerTasks_pay',
    'resellerTasks_confirmGettingPaid',
    'sellerTasks_confirmOrder',
    'sellerTasks_confirmGettingPaid',
    'sellerTasks_ship',
    'sellerTasks_pay',
    'doneTasks_shipped',
    'doneTasks_autoCompleted',
    'doneTasks_cancelled',
  ],
  ORDER_SUMMARY_NO_RESELLER: [
    'myTasks_confirmOrder',
    'myTasks_pay',
    'myTasks_confirmGettingPaid',
    'myTasks_ship',
    'custTasks_pay',
    'custTasks_autoComplete',
    'sellerTasks_confirmOrder',
    'sellerTasks_confirmGettingPaid',
    'sellerTasks_ship',
    'sellerTasks_pay',
    'doneTasks_shipped',
    'doneTasks_autoCompleted',
    'doneTasks_cancelled',
  ],
  ORDER_SUMMARY_NO_SELLER: [
    'myTasks_confirmOrder',
    'myTasks_pay',
    'myTasks_confirmGettingPaid',
    'myTasks_ship',
    'custTasks_pay',
    'custTasks_autoComplete',
    'resellerTasks_confirmOrder',
    'resellerTasks_pay',
    'resellerTasks_confirmGettingPaid',
    'doneTasks_shipped',
    'doneTasks_autoCompleted',
    'doneTasks_cancelled',
  ],
  ORDER_SUMMARY_NO_SELLER_AND_RESELLER: [
    'myTasks_confirmOrder',
    'myTasks_pay',
    'myTasks_confirmGettingPaid',
    'myTasks_ship',
    'custTasks_pay',
    'custTasks_autoComplete',
    'doneTasks_shipped',
    'doneTasks_autoCompleted',
    'doneTasks_cancelled',
  ],
  ORDER_SUMMARY_SPECIAL_TAB_KEYS: [
    'myTasks_paySalesOrdersPaid',
    'myTasks_payOther',
    'xShipping_HasTrackingNo',
    'xShipping_Requesting',
    'xShipping_PendingRequestAuto',
    'xShipping_PendingRequestManual',
    'xShipping_RequestFailed',
    'xShipping_Cancelling',
    'xShipping_CancelFailed',
    'myTasks_ShippableOrder',
    'myTasks_ship_not_ready',
    'myTasks_ship_all',
  ],
  // ORDER_SUMMARY_SPECIAL_TAB_KEYS: ['myTasks_paySalesOrdersPaid'],
  PDF_URL_REQ_DATE_FORMAT: 'YYYY-MM-DD%20HH:mm:ss.SSS',
  PDF_REPORT_DATE_FORMAT: 'YYYY-MM-DD%20HH:mm:ss',
  ORDER_STATE: {
    CANCELLED_BY_SELLER: 181,
    CANCELLED_BY_BUYER: 182,
    CANCELLED_BY_SYSTEM: 183,
    CANCELLED_BY_EXPIRED: 184,
  },
  ORDER_OPERATION_AVAILABILITY: {
    CONFIRM: {
      // order_state
      _1101: 0,
      _2101: 1,
      _3101: 2,

      _1102: 1,
      _2102: 1,
      _3102: 0,

      _1103: 0,
      _2103: 0,
      _3103: 2,

      _1109: 0,
      _2109: 0,
      _3109: 0,

      // cancel_states
      _1181: 0,
      _2181: 0,
      _3181: 0,

      _1182: 0,
      _2182: 0,
      _3182: 0,

      _1183: 0,
      _2183: 0,
      _3183: 0,

      _1184: 0,
      _2184: 0,
      _3184: 0,
    },
    PAY: {
      _1111: 2, // บันทึกรับชำระ
      _2111: 7, // บันทึกชำระแทนผู้ซื้อ ( ปุ่มนี้จะแสดงก็ต่อเมื่อ ship_before_pay = true ด้วยเท่านั้น )
      _3111: 1, // แจ้งชำระ

      _1112: 4, // แจ้งคืนเงินลูกค้า
      _2112: 5, // แจ้งคืนเงินตัวแทน
      _3112: 0,

      _1113: 3, // ยืนยันรับชำระ
      _2113: 3,
      _3113: 0, // เดิม 3 แก้เป็น 0 จาก state sheet

      _1114: 3,
      _2114: 3,
      _3114: 0,

      _1115: 0,
      _2115: 0,
      _3115: 6, // ยืนยันรับเงินคืนจากผู้ขายส่ง

      _1119: 0,
      _2119: 0,
      _3119: 0,
      DISABLED: 0,
      CONFIRM_GETTING_PAID: 3,
      CONFIRM_GETTING_REFUND: 6,
    },
    SHIP: {
      _1121: 1,
      _2121: 1,
      _3121: 0,

      _1122: 0,
      _2122: 0,
      _3122: 0,

      _1129: 0,
      _2129: 0,
      _3129: 0,
    },
    EDIT: {
      // order_state
      _1101: 0,
      _2101: 0,
      _3101: 0,

      _1102: 0,
      _2102: 0,
      _3102: 0,

      _1103: 0,
      _2103: 0,
      _3103: 0,

      _1109: 1,
      _2109: 1,
      _3109: 1,

      // cancel_states
      _1181: 1,
      _2181: 1,
      _3181: 1,

      _1182: 1,
      _2182: 1,
      _3182: 1,

      _1183: 1,
      _2183: 1,
      _3183: 1,

      _1184: 1,
      _2184: 1,
      _3184: 1,
    },
  },
  BTN_MODE: {
    ACTIVE_PRIMARY: 1,
    ACTIVE_SECONDARY: 2,
    ACTIVE_BLUE_SECONDARY: 4,
    INACTIVE: 3,
  },
  EVENT_NAME: {
    SHARE_IMG: 'shareImg',
    ORDER_CALC_SHIPPING_COST_SUCCESS: 'order_calc_shipping_cost_success', // success
    ORDER_CALC_SHIPPING_COST_ERROR: 'order_calc_shipping_cost_error', // success with error
    ORDER_CALC_SHIPPING_COST_FAILED: 'order_calc_shipping_cost_failed', // failed
    REPORT_DAILY: 'reportDaily',
    REPORT_DAILY_EVENTS: ['rdSales', 'rdProfit', 'rdOrder'],
    REPORT_DAILY_SELECT_PERIOD: 'reportDailySelectPeriod',
    REPORT_DAILY_PRINT_EXCEL: 'reportDailyPrintExcel',
    REPORT_RESELLER: 'reportReseller',
    REPORT_SALES_BY_CHANNELS: 'reportSalesByChannel',
    REPORT_SHIPPING: 'reportShipping',
    REPORT_REQUISITION: 'reportRequisition',

    REPROT_STOCK_CARD: 'reportStockCard',
    REPORT_PRODUCT_DAILY_SALES: 'reportProductDailySales',
    REPORT_SHIPPED_ORDERS: 'reportShippedOrders',
    REPORT_COMPLETE_SALES: 'reportCompleteSales',
    REPORT_STOCK: 'ReportStock',
    REPORT_PRODUCT_HELPER_SALES: 'reportHelperSales',
    REPORT_PRODUCT_PAYMENT: 'reportPayment',

    SHIPPING_LABLE_MULTI_A4: 'shippingLabelMultiA4',
    SHIPPING_LABLE_SINGLE_A4: 'shippingLabelSingleA4',
    SHIPPING_LABLE_MULTI_PPR: 'shippingLabelMultiPPR',
    SHIPPING_LABLE_SINGLE_PPR: 'shippingLabelSinglePPR',
    SHIPPING_LABLE_MULTI_PDF_STICKER: 'shippingLabelMultiPDFSticker',

    RECEIPT_PPR: 'receiptPPR',

    HELP_VIEW_MY_STORES: 'helpViewMyStores',
    HELP_VIEW_SETTINGS: 'helpViewSettings',

    ORDER_PUBLIC_LINK_SHARE: 'orderPublicLinkShare',
    ORDER_PUBLIC_LINK_COPY: 'orderPublicLinkCopy',
    ORDER_PUBLIC_LINK_OPEN: 'orderPublicLinkOpen',
    ORDER_COPY_DETAIL: 'orderCopyDetail',
  },
  STORE_ROLE: {
    OWNER: 1,
    HELPER: 2,
    RESELLER: 3,
  },
  STORE_SETTINGS: {
    AUTO_CALC_SHIPPING_COST: 's_auto_calc_shipping_cost',
    SHIPPING_RATE_BY_WEIGHT_AT_COUNT: 's_shipping_rate_by_weight_at_count',
    SHIPPING_CALC_MODE: 's_shipping_calc_mode',
    SHIPPING_CALC_MODE_QTY: 1,
    SHIPPING_CALC_MODE_WEIGHT: 2,
    SHIPPING_CALC_MODE_QTY_AND_WEIGHT: 3,
    CALC_SHIPPING_MEASURE_QTY: 1,
    CALC_SHIPPING_MEASURE_WEIGHT: 2,

    // Binary Flags
    BIN_USE_PRODUCT_BARCODE: 's_use_product_barcode',

    // Front-end Compute after fetch
    COMPUTED_USE_PRODUCT_SKU: 's_computed_use_product_sku', // TODO: Add adapter in store.ts saga
    COMPUTED_USE_PRODUCT_UPC: 's_computed_use_product_upc', // TODO: Add adapter in store.ts saga
  },
  SELECTED_STORE_LOCAL_CONFIGS: {
    CAN_AUTO_CALC_SHIPPING_RATE_BY_QTY: 'canAutoCalcShippingRateByQty',
    CAN_AUTO_CALC_SHIPPING_RATE_BY_WEIGHT: 'canAutoCalcShippingRateByWeight',
  },
  STORAGE_KEYS: {
    APP_PROPERTY: 'XSelly::PROPERTY',
    F_UNKNOWN_DEVICE_ALERT: 'XSelly::F_UNKNOWN_DEVICE_ALERT',
    FCM_TOKEN: 'XSelly::FCM_TOKEN',
    FCM_NOTI_NAV_CACHE: 'XSelly::FCM::NOTIFICATION_CACHE_NAVIGATION',
    FCM_MESSAGES: 'XSelly::FCM_MESSAGES',
    USER_PROFILE: 'XSelly::USER_PROFILE',
    FBDL_INIT: 'XSelly::FBDL::INIT',
    RNDL_INIT: 'XSelly::RNDL::INIT',
    FBDL_LATEST: 'XSelly::FBDL::LATEST',
    RNDL_LATEST: 'XSelly::RNDL::LATEST',
    SUBSCRIPTION_ORDER_TRACKER: 'XSelly::SBT_ORDER',
    SUBSCRIPTION_PACKAGE_TRACKER: 'XSelly::SBT_PACKAGE',
    SUBSCRIPTION_PACKAGE_QUOTA_TRACKER: 'XSelly::SBT_PACKAGE_QUOTA_TRACKER',
    REPORT_REQUIS_SHOW_COST: 'XSelly_REPORT_REQUIS_SHOW_COST',
    REPORT_REQUIS_WAIT_TO_REFUND_ORDERS: 'XSelly_REPORT_REQUIS_WAIT_TO_REFUND_ORDERS',
    REPORT_REQUIS_WAIT_TO_SHIP_ORDERS: 'XSelly_REPORT_REQUIS_WAIT_TO_SHIP_PRDERS',
    REPORT_REQUIS_SHOW_TOTAL_COST: 'REPORT_REQUIS_SHOW_TOTAL_COST',
    REPORT_REQUIS_BTN_RADIO_STANDARD: 'REPORT_REQUIS_BTN_RADIO_STANDARD',
    REPORT_REQUIS_BTN_RADIO_FLASG_EXPRESS: 'REPORT_REQUIS_BTN_RADIO_FLASG_EXPRESS',
    REPORT_REQUIS_BTN_RADIO_KERRY_EXPRESS: 'REPORT_REQUIS_BTN_RADIO_KERRY_EXPRESS',
    REPORT_REQUIS_BTN_RADIO_KERRY_EXPRESS_2: 'REPORT_REQUIS_BTN_RADIO_KERRY_EXPRESS_2',
    REPORT_REQUIS_BTN_SEGMENT_DATETIME: 'REPORT_REQUIS_BTN_SEGMENT_DATETIME',
    REPORT_REQUIS_BTN_SEGMENT_SHIPPING_TYPE: 'REPORT_REQUIS_BTN_SEGMENT_SHIPPING_TYPE',

    MKP_CHANNEL_RE_AUTH_TRACKER: 'XSelly::MKP_CHANNEL_RE_AUTH',

    COD_CALCULATOR: 'XSelly::COD_CALCULATOR',
    USE_XSHIPPING: 'XSelly::USE_XSHIPPING',

    REPORT_PRODUCT_DAILY_SALES: 'XSelly::REPORT_PRODUCT_DAILY_SALES',

    SCAN_ORTION_ORDER: 'XSelly::SCAN_ORTION_ORDER',
    SCAN_ORTION_TRACKING: 'XSelly::SCAN_ORTION_TRACKING',

    PRINTING_XOVERLAY_OPTION_KEY: 'XSELLY::PRINTING_XOVERLAY_OPTION_KEY',

    DEV_TOOLBOX_USER_HASH: 'XSelly:DEV_TOOLBOX_USER_HASH',
    DEV_TOOLBOX_REACTOTRON_HOST_URL: 'XSelly:DEV_TOOLBOX_REACTOTRON_HOST_URL',

    ORDER_PERCENT_DISCOUNT_DATA: 'XSelly::ORDER_PERCENT_DISCOUNT_DATA',

    REPORT_STOCK: 'XSelly::REPORT_STOCK',
    REPORT_STOCK_REPORT_STYLE: 'XSelly::REPORT_STOCK_REPORT_STYLE',
    REPORT_PEAK: 'XSelly::REPORT_PEAK',

    CREATE_ORDER_LAST_SELECTED_STORE: 'XSelly::CREATE_ORDER_LAST_SELECTED_STORE',
    CREATE_ORDER_LAST_SELECTED_PG_UG: 'XSelly::CREATE_ORDER_LAST_SELECTED_PG_UG',
    CREATE_ORDER_LAST_SELECTED_PG_UG_INDEX: 'XSelly::CREATE_ORDER_LAST_SELECTED_PG_UG_INDEX',
  },
  MKP_NAME: {
    shopee: 'Shopee',
    lazada: 'Lazada',
    tiktok: 'Tiktok Shop ',
    lineshopping: 'Line Shopping',
  },
  PAYMENT_STATUS: {
    OPENED: 0,
    CONFIRMED: 1,
    REJECTED_OR_CANCELED: 2,
    // CANCELED: 3,
  },
  PAYMENT_METHOD: {
    BANK_TRANSFER: 0,
    GBPAY: 1,
    SCB_QR_CODE: 2,
    BEAM: 3,
  } as const,
  VERIFY_SLIP_STATUS: {
    InvalidQRCode: 'InvalidQRCode',
    InsufficientCredit: 'InsufficientCredit',
    TransactionRejected: 'TransactionRejected',
    RemoteServerError: 'RemoteServerError',
    InvalidReceivingPaymentAccount: 'InvalidReceivingPaymentAccount',
    SlipAlreadyUsed: 'SlipAlreadyUsed',
    SlipExpired: 'SlipExpired',
  } as const,
  VERIFY_SLIP_CREDIT_HISTORY_ACTION: {
    USER_DEPOSIT: 101,
    CAMPAIGN_DEPOSIT: 102,
    SYSTEM_DEPOSIT: 109,
    USER_VERIFY_SLIP: 201,
    W4C_VERIFY_SLIP: 202,
    SALEPAGE_VERIFY_SLIP: 203,
    WEB_CATALOG_VERIFY_SLIP: 204,
    CHAT_LINE_VERIFY_SLIP: 205,
    CHAT_FB_VERIFY_SLIP: 206,
    USER_WITHDRAW: 301,
    SYSTEM_WITHDRAW: 309,
  } as const,
  VERIFY_SLIP_USAGE_ACTION_IDS: [201, 202, 203, 204, 205, 206] as const,
  // ref: https://dd.xselly.com/doc/bank-code-bNdiidCpP0
  VERIFY_SLIP_BANK_CODE_TO_BANK_ID: {
    PROMPTPAY: 24, // promptpay
    '004': 3, // kbank
    '025': 4, // bay
    '006': 5, // ktb
    '014': 6, // scb
    '002': 7, // bkk
    '011': 8, // tmb
    '030': 9, // gsb
    '022': 10, // cimb
    '066': 11, // ibank
    '069': 12, // kk
    '067': 13, // tisco
    '065': 14, // tnc
    '024': 15, // uob
    '020': 16, // scnb
    '071': 17, // trcbank
    '073': 18, // lhbank
    '034': 20, // baac
    '033': 22, // ghb
    '070': 23, // icbc
    '031': 39, // hsbc
    '039': 40, // mizuho
    '017': 41, // citi
  },
  PERM_STORE_OWNER_ONLY: 'owner_only', // for Acl force disallow in helper
  PERM_STORE_HELPER: {
    CUSTOM_ORDER_VIEW: 'custom_order_view',
    DASHBOARD: 'bin_dashboard',
    ORDER_LIST: 'order_list',
    ORDER_ADD: 'order_add',
    ORDER_EDIT: 'order_edit',
    ORDER_CANCEL: 'order_cancel',
    ORDER_PRINT_SHIPPING: 'order_print_shipping',
    ORDER_VISIBLE_ONLY_PROFILE_ID: 'order_visible_only_profile_id',
    PRODUCT_LIST: 'product_list',
    PRODUCT_ADD: 'product_add',
    PRODUCT_PULL: 'product_pull',
    PRODUCT_EDIT: 'product_edit',
    PRODUCT_DELETE: 'product_delete',
    PRODUCT_COST: 'product_cost',
    PRODUCT_PRICE: 'product_price',
    PRODUCT_STOCK_VIEW: 'product_stock_view',
    PRODUCT_STOCK_EDIT: 'product_stock_edit',
    PRODUCT_SHIPPING_QTY_RATE: 'product_shipping_qty_rate',
    PRODUCT_WEIGHT: 'product_weight',
    REPORT_VIEW: 'report_view',
    MEMBER_LIST: 'member_list',
    MEMBER_ADD: 'member_add',
    MEMBER_EDIT: 'member_edit',
    MEMBER_DELETE: 'member_delete',
    UG_LIST: 'ug_list',
    UG_ADD: 'ug_add',
    UG_EDIT: 'ug_edit',
    UG_DELETE: 'ug_delete',
    PG_LIST: 'pg_list',
    PG_ADD: 'pg_add',
    PG_EDIT: 'pg_edit',
    PG_DELETE: 'pg_delete',
    VD_LIST: 'vd_list',
    PAYMENT_LIST: 'payment_list',
    PAYMENT_ADD: 'payment_add',
    PAYMENT_EDIT: 'payment_edit',
    PAYMENT_DELETE: 'payment_delete',
    SHIPPING_LIST: 'shipping_list',
    SHIPPING_ADD: 'shipping_add',
    SHIPPING_EDIT: 'shipping_edit',
    SHIPPING_DELETE: 'shipping_delete',
    SHOPPING_ASSISTANT_ADD: 'shopping_assistant_add',
    SHOPPING_ASSISTANT_EDIT: 'shopping_assistant_edit',
    SHOPPING_ASSISTANT_DELETE: 'shopping_assistant_delete',
    SHOPPING_ASSISTANT_LIST: 'shopping_assistant_list',
    // TODO: Binary code [2 = sku / 1 = upc] get from 'product_code_edit'
    PRODUCT_CODE_EDIT: 'product_code_edit',
    PRODUCT_CODE_EDIT_SKU: 'product_code_edit_sku',
    PRODUCT_CODE_EDIT_UPC: 'product_code_edit_upc',
    // REPORT
    REPORT_DAILY_SALES: 'report_daily_sales',
    REPORT_COMPLETE_SALES: 'report_complete_sales',
    REPORT_SALES_BY_CHANNELS: 'report_sales_by_channels',
    REPORT_STOCK: 'report_stock',
    REPORT_PRODUCT_DAILY_SALES: 'report_product_daily_sales',
    REPROT_STOCK_CARD: 'report_stock_card',
    REPORT_SHIPPED: 'report_shipped',
    REPORT_REQUISITION: 'report_requisition',
    REPORT_RESELLER: 'report_reseller',
    REPORT_HELPER: 'report_helper',
    REPORT_PAYMENT: 'report_payment',
    REPORT_ORDER: 'report_order',
    REPORT_INVENTORY: 'report_inventory',
    REPORT_SHIPPING: 'report_shipping',
    REPORT_PEAK_ACCOUNT: 'report_peak_account',

    REPORT_STOCK_HISTORY: 'report_stock_history',

    WH_LIST: 'wh_list',
    WH_ADD: 'wh_add',
    WH_EDIT: 'wh_edit',
    WH_DELETE: 'wh_delete',

    MKP_LIST: 'mkp_list',
    MKP_ADD: 'mkp_add',
    MKP_EDIT: 'mkp_edit',
    MKP_DELETE: 'mkp_delete',

    // ERP
    ERP_CHANNEL_ADD: 'erp_channel_add',
    ERP_CHANNEL_DELETE: 'erp_channel_delete',
    ERP_CHANNEL_EDIT: 'erp_channel_edit',
    ERP_CHANNEL_LIST: 'erp_channel_list',
    ERP_CHANNEL_SET_ACTIVE: 'erp_channel_set_active',
    ERP_DOC_CANCEL: 'erp_doc_cancel',
    ERP_DOC_CREATE: 'erp_doc_create',

    CONTACT_LIST: 'contact_list',
    CONTACT_ADD: 'contact_add',
    CONTACT_EDIT: 'contact_edit',
    CONTACT_DELETE: 'contact_delete',
  } as const,
  REPORT_GROUP_KEY: {
    ALL: [
      'report_daily_sales',
      'report_complete_sales',
      'report_sales_by_channels',
      'report_stock',
      'report_stock_history',
      'report_product_daily_sales',
      'report_stock_card',

      'report_shipped',
      'report_requisition',

      'report_reseller',

      'report_helper',

      'report_payment',

      'report_peak_account',
    ],
    REPORT_ORDER: ['report_daily_sales', 'report_complete_sales', 'report_sales_by_channels', 'report_peak_account'],
    REPORT_INVENTORY: ['report_stock', 'report_product_daily_sales', 'report_stock_card', 'report_stock_history'],
    REPORT_SHIPPING: ['report_shipped', 'report_requisition'],
    REPORT_RESELLER: ['report_reseller'],
    REPORT_HELPER: ['report_helper'],
    REPORT_PAYMENT: ['report_payment'],
  },
  REPORT_SET_BIT_BINARY: {
    report_daily_sales: 1,
    report_complete_sales: 2,
    report_sales_by_channels: 4,
    report_stock: 1,
    report_product_daily_sales: 2,
    report_stock_card: 4,
    report_shipped: 1,
    report_requisition: 2,
    report_reseller: 1,
    report_helper: 1,
    report_payment: 1,
    report_peak_account: 8,

    report_stock_history: 8,
  },
  UKU_UPC_BIT_BINARY: {
    UPC: 1,
    SKU: 2,
  },
  DASHBOARD_PERM_REPORTS: {
    bin_dashboard: 'bin_dashboard',
  },
  DASHBOARD_BIT_BINARY: {
    bin_dashboard: 1,
  },
  PRODUCT_ATTR: {
    NAME: 'name',
    COST: 'cost',
    PRICE: 'price',
    PRICE_RETAIL: 'price_retail',
    QTY: 'qty',
    AVAILABLE_QTY: 'available_qty',
    WEIGHT: 'weight',
    H: 'h',
    SKU: 'sku',
    UPC: 'upc',
    CATEGORY: 'category',
  },
  REPORT_DAILY_DATE_FORMAT: 'YYYY-MM-DD',
  TOAST_TYPE: {
    SUCCESS: 'success',
    DANGER: 'danger',
    WARNING: 'warning',
  },
  ERR_CODE: {
    // user_not_found: 'user_not_found',
    // DUPLICATED_EMAIL: '01_075',
    // DUPLICATED_USERNAME: '01_076',
    // FIRST_NAME_NO_INPUT: '01_107',
    LOGIN_ACCESS_DENIED: '02_004',
    USER_NOT_FOUND: '03_007',
    // UPDATE_EMAIL_INVALID_FORMAT: '03_096',
    // UPDATE_EMAIL_DUPLICATE: '03_075',
    // PROFILE_ID_IS_NULL: `04_005`,
    // PROFILE_NOT_FOUND: `04_007`,
    // STORE_ID_IS_NULL_FROM_REPORT_DEALER_COUNT_RESELLERS: '06_005',
    // UPC_CLOSE_FLAG: '06_071',
    // SKU_CLOSE_FLAG: '06_072',
    // ORDER_LOCK: '07_052',
    // ORDER_ALREADY_CANCELLED: '07_037',
    // PRODUCT_TEMPLATE_ID_NOT_FOUND: '09_005',
    // PRODUCT_PRODUCT_ID_NOT_FOUND: '10_007',
    // PRODUCT_PRODUCT_OUT_OF_STOCK: '10_028',
    // PRODUCT_PRODUCT_DUPLICATE_SKU: '10_055',
    PERMISSION_DENIED: '15_100',
    // RESELLER_DETAIL_IS_NOT_YOU_MEMBER: '15_105',
    // VD_QUOTA_EXCEEDED: '22_124',
    // VD_NOT_ALLOW_TO_ADD: '22_020',
    // EDIT_NOTES_DOUBLY: '23_041',
    // SHIP_BEFORE_PAY_IS_NOT_ALLOW_MY_STORE: '07_083',
    // SHIP_BEFORE_PAY_IS_NOT_ALLOW_SELLER_STORE: '07_084',
    // STORE_ID_NOT_FOUND: '06_007',
    // STORE_ID_IS_NULL_FROM_PROFILE_ID: '06_047',
    LEAST_ONE_PRINTING_TYPE_NEED_ACTIVE: '06_086',
    // PAGEPICKER_TIME_OUT_OR_ORDER_FULL: '06_090',
    // SELLER_CANT_RECEIVE_ORDER: '30_090',
    // SELLER_CANT_RECEIVE_ORDER_2: '030_090',
    NOT_FOUND: '05_099',
    // BODY_PERMISSION_OF_NULL: '73_047',
    // USER_ID_OF_NULL: '73_007',
    // PERMISSION_HAVE_USER: '73_064',
    // PERMISSION_ID_OF_NULL: '73_099',
    // HELPER_HAVE_PERMISSION: '73_044',
    // HELPER_NO_PERMISSION_TO_ADD_ADDRESS: '15_020',
    // HELPER_NO_PERMISSION_TO_DELETE_ADDRESS: '15_020',
    // HELPER_NO_PERMISSION_TO_EDIT: '15_021',
    // HELPER_NO_PERMISSION: '15_023',
    // HELPER_COUNT_FULL: '73_087',
    // USER_ID_OF_NULL_OF_PERMISSION_API: '73_007',
    // SELLER_DONT_SUPPORT: '00_081',

    // PARENT_CATEGORY_ID_NOT_FOUND: '74_007',
    // CATEGORY_SORT_ORDER_IS_EMPTY: '74_005',
    // CATEGORY_SORT_ORDER_IS_NOT_UP_TO_DATE: '74_047',
    // CATEGORY_NO_PERMISSION: '15_004',
    // NESTED_CHILDREN_ID: `74_093`,
    // CATEGORY_SAME_PLACE: `74_094`,
    // CATEGORY_SAME_NAME: `74_010`,

    // ORGANIZATION_IS_EMPTY: `76_005`,
    // ORGANIZATION_IS_NOT_FOUND: `76_007`,
    // ORGANIZATION_IS_NOT_NUMBER: `76_047`,
    // FILE_IMPORT_NEW_USER_IS_NULL: `78_005`,
    // FILE_IMPORT_NEW_USER_IS_HAVE_MERGE_CELL: `78_017`,
    // NOT_EXCEL_FILE_IMPORT_NEW_USER: `78_047`,
    // INVITE_CODE_USER_GROUP_FAILED: `20_007`,
    // UG_QUOTA_EXCEEDED: `20_033`,
    // EMAIL_IS_NOT_FORMAT: `01_096`,
    // POSTAL_CODE_IS_NOT_FORMAT: `05_097`,
    // ADDRESS_IS_LIMIT: `05_087`,
    // ADVISER_CODE_NOT_FOUND: `79_007`,
    // ADDRESS_IS_NOT_COMPLETE: `05_005`,
    // RESELLER_CODE_IS_NOT_FORMAT: `79_098`,
    // RESELLER_CODE_DUPLICATE: `79_101`,

    // MUMBER_TYPE_NOT_TRUE: `79_102`,
    // COLUMN_HEADER_NAME_NOT_TRUE: `78_103`,
    // EMAIL_NO_INPUT: `82_005`,
    // EMAIL_INVALID_FORMAT: `82_096`,
    ADDRESS_NOT_FOUND: `05_007`,
    // POSTAL_CODE_NO_INPUT: `05_106`,

    // RELLSWE_CODE_NOT_FOUND: '07_507',
    // NOT_A_MEMBER: '07_553',
    // DONT_HAVE_PERMISSION_IMPORT_EXCEL: '15_104',
    NOT_TEAM: '30_553',
    // REFERAL_RESELLER_CODE_NO_INPUT: '80_005',
    // REFERAL_RESELLER_CODE_NOT_FOUND: '80_007',
    // REFERAL_RESELLER_CODE_INVALID_FORMAT: '80_098',
    // RESELLER_CODE_NO_INPUT: `81_005`,
    // RESELLER_CODE_NOT_FOUND: `81_007`,
    // RESELLER_CODE_DUPLICATE_DURING_APPROVE: `81_110`,
    // RESELLER_CODE_IS_NOT_MEMBER: `81_105`,
    // PASSWORD_NO_INPUT: '83_005',

    // RESELLER_TYPE_NO_INPUT: '84_005',
    // RESELLER_TYPE_IS_WRONG: '84_047',

    // TO_BE_TRANSFERRED_RESELLER_CODE: '85_111',
    // NEW_PARENT_RESELLER_CODE_HAS_TO_BE_TRANSFERRED_RESELLER_CODE: '85_112',
    // NEW_PARENT_RESELLER_CODE_IS_TO_BE_TRANSFERRED_RESELLER_CODE: '85_113',
    // NEW_PARENT_RESELLER_CODE_IS_DUPLICATE: '85_114',

    // HAVE_CHILDREN_OF_THE_TYPE_VIP_MEMBERSHIP: '85_115',
    // NOT_DEALER_TYPE: '87_117',
    // PRODUCT_DETAIL_MKP_NOT_FOUND: '89_007',

    // MKP_CHANNEL_ID_NO_INPUT: '90_005',
    // MKP_CHANNEL_ID_NOT_FOUND: '90_007',
    // ITEM_ID_NOT_FOUND: '92_007',

    TOKEN_EXPIRED: 'token_expired',
    TOKEN_INVALID: 'token_invalid',
    TOKEN_NOT_PROVIDED: 'token_not_provided',
    EMAIL_DUPLICATE: 'The email has already been taken.',

    // // เลิกดัก Error V2 ที่นี้ เปลี่ยนไปถอดรหัสใน th.ts -> e_msg_by_error_code แทน
    // UNMATCHED_CUSTOM_PACKING_TEMPLATE_ERROR: 'UnmatchedCustomPackingTemplateError',
    // STORE_NOT_FOUND: 'StoreNotFound',
    // PAYMENT_ACCOUNT_NOT_FOUND: 'PaymentAccountNotFound',
    // PERMISSION_NOT_FOUND: 'PermissionNotFound',
    // PAYMENT_ACCOUNT_ID: 'PaymentAccountId',
    // BAD_REQUEST: 'BadRequest',
    // INVALID_SEGMENT: 'InvalidSegment',
    // PERMISSION_ACCESS_DENIED: 'PermissionAccessDenied',
    // CREATE_KAFKA_EVENT_FAIL: 'CreateKafkaEventFail',
    // JSON_DECODE_FAIL: 'JsonDecodeFail',
    // PROFILE_INVALID: 'ProfileInvalid',
    // PAYLOAD_INVALID: 'PayloadInvalid',
    // INTERNAL_SERVER_ERROR: 'InternalServerError',
  },
  ALOG: {
    API_ERROR: 'API_ERROR',
    API_CANNOT_PARSE_ERROR: 'API_CANNOT_PARSE_ERROR',
    API_ERROR_PERM_DENIED: 'API_ERROR_PermissionDenied',
    API_ERROR_UNAUTH: 'API_ERROR_Unauthorized',
    API_ERROR_UNKNOWN: 'API_ERROR_Unknown',
    API_ERROR_UNPROCESSABLE_ENTITY: 'API_ERROR_UnprocessableEntity',
    RETRY: 'RETRY',
    RETRY_FAILED: 'RETRY_FAILED',
  },
  COPY_LINK_ORDER: {
    COPY_LINK: 'COPY_LINK',
    OPEN_LINK: 'OPEN_LINK',
    SHARE_LINK: 'SHARE_LINK',
  },
  ORDER_DISCOUNT_KEY: {
    UGPG: 'ugpg',
    VD: 'vd',
  },
  NO_AUTO_SAVE_IMG_AT_HEIGHT_BELOW_SHIPING_LABEL: {
    COVER_SHEET: 150,
  },

  // เอาไว้สำหรับเป็น constants ที่ใช้งานแยก Platform web/app ใน xui
  SPECIFIC_PLATFORM: {
    WEB: {
      // SIDEBAR_WIDTH: 180,
      // MAIN_CONTENT_WIDTH: 1050,
      // MAX_WEB_WIDTH: 1050 + 180, // MAX_WEB_WIDTH: MAIN_CONTENT_WIDTH + SIDEBAR_WIDTH,
      SIDEBAR_COLLAPSED_WIDTH: 60,
      SIDEBAR_WIDTH: 200,
      NUM_OF_COLUMN: 3,
      MAIN_CONTENT_WIDTH: 1050,
      MAIN_CONTENT_ONE_COLUMN_WIDTH: 350, //  MAIN_CONTENT_WIDTH / NUM_OF_COLUMN
      MAX_WEB_WIDTH: 1250, // MAX_WEB_WIDTH: MAIN_CONTENT_WIDTH + SIDEBAR_WIDTH,
      HEADER_HEIGHT: 44,
      MIN_WEB_HEIGHT: 500,
      BOTTOM_SCROLLBAR_HEIGHT: 15,
      RIGHT_SCROLLBAR_WIDTH: 15,
      NAV_HEADER_HEIGHT: 44,
      SCROLLBAR_WIDTH: 16,
    },
    APP: {},
  },

  DEFAULT_BARCODE: {
    t: 3,
    s: 1,
    a: true,
    p: {
      i: 8040,
      t: 'q',
      l: 'th',
      n: 1,
      d: 1,
      c: 1,
      v: 1,
    },
  },

  // PRINTNG_KEY: {
  //   PAPERANG: 'PAPERANG' as PrintOptionKey,
  //   A4_PDF: 'A4_PDF' as PrintOptionKey,
  //   STICKER_PDF: 'STICKER_PDF' as PrintOptionKey,
  //   BILL_PAPERANG: 'BILL_PAPERANG' as PrintOptionKey,
  // },

  // ------------------------------------------------
  // Background Job Constants
  // ต้องถ้าเพิ่ม zone นี้ BACKGROUND_JOB_TYPES BGJOB_TYPE_MAPTEXT จะต้องเพิ่มตาม key ให้สอดคล้องกัน
  BACKGROUND_JOB_TYPES: {
    SHIPPING_CREATE: 'shipping.create',
    PAYMENT_CREATE: 'payment.create',
    MKP_ORDER_CONFIRM: 'mkp.order.confirm',
    MKP_SYNC_PRODUCT: 'mkp.sync.product',
    MKP_SYNC_ORDER: 'mkp.sync.order',
    XSHIPPING_STATUS_UPDATE: 'xshipping.status.update',
    IMPORT_PAYMENT_COD: 'import.payment.cod',
    IMPORT_ORDER_CREATE: 'import.order.create',
    PRODUCT_QTY_UPDATE: 'product.qty.update',
    PRODUCT_QTY_ADD: 'product.qty.add',
    IMPORT_PRODUCT_STOCK_QTY_EDIT: 'import.product.stock.qty.edit',
    ERP_CREATE_DOCUMENT: 'erp.create.document',
    ERP_SYNC_DOCUMENT: 'erp.sync.document',
    ERP_SYNC_STATUS_DOCUMENT: 'erp.sync.status.document',
  } as const,
  BGJOB_TYPE_MAPTEXT: {
    'shipping.create': 'บันทึกจัดส่งอัตโนมัติ',
    'payment.create': 'บันทึกรับชำระอัตโนมัติ',
    'mkp.order.confirm': 'ยืนยันออเดอร์',
    'mkp.sync.product': 'ซิงค์ข้อมูลสินค้า',
    'mkp.sync.order': 'ซิงค์ข้อมูลออเดอร์',
    'product.qty.update': 'นำเข้าจำนวนคลังแบบชุด',
    'product.barcode.update': 'นำเข้ารหัสสินค้าแบบชุด',
    'xshipping.status.update': 'อัปเดตสถานะการจัดส่งอัตโนมัติ',
    'import.payment.cod': 'กระทบยอด COD',
    'product.qty.add': 'นำเข้าจำนวนคลังแบบชุด',
    'import.product.stock.qty.edit': 'แก้ไขคลังสินค้า',
    'erp.create.document': 'สร้างเอกสารที่ระบบบัญชี',
    'erp.sync.document': 'ซิงค์เอกสารกับระบบบัญชี',
    'erp.sync.status.document': 'ซิงค์เอกสารกับระบบบัญชี',
    'import.order.create': 'สร้างออเดอร์จากไฟล์ Excel',
  },
  // BGTASK_ERROR_MAPTEXT: {
  //   InvalidCreateShipping: 'ปิดจัดส่งไม่ถูกต้อง',
  // },
  // ------------------------------------------------
  MULTI_OP_MODES: {
    PrintShippingLabels: 'PrintShippingLabels',
    ConfirmOrders: 'ConfirmOrders',
    AutoCompletePayment: 'AutoCompletePayment',
    AutoCompleteShipment: 'AutoCompleteShipment',
    ConfirmMkpOrders: 'ConfirmMkpOrders',
  } as const,

  SYSTEM_ID: {
    SHIPJUNG: 2,
    J_AND_T: 3,
  },
  // สำหรับ mkp ที่สามารถเลือก นัดรับ และ drop off
  MKP_PICKUP_AND_DROPOFF: [1, 2, 3],

  ITAMS_SUBSCRIPTION_PACKAGE: {
    ONE_MONTH: ['PACKAGE-BRONZE-CYCLE-1', 'PACKAGE-SILVER-CYCLE-1', 'PACKAGE-GOLD-CYCLE-1', 'PLATINUM-CYCLE-1'],
    THREE_MONTH: [''],
  },

  SALE_PAGE_ITEMS_KEY: {
    COUNTDOWN_TIMER_IN_MINUTE: 'countdown_timer_in_minute',
  },

  PURCHASABLES_ID: {
    SCB: 1,
    IOS: 2,
    GOOGLE: 3,
  },
}

/*
export function getOrderStateKey({ type, state }) {
  let task = type.toString() + state.toString()
  // console.log('getOrderStateKey_task: ', task)
      // รอฉัน
  if (_.includes(['2102', '3101', '3103'], task)) {
    return 'myTasks_confirmOrder'
  } else if (_.includes(['3104'], task)) {
    return 'myTasks_pay'
  } else if (_.includes(['1104', '2105'], task)) {
    return 'myTasks_confirmGettingPaid'
  } else if (_.includes(['1106', '2106'], task)) {
    return 'myTasks_ship'

      // รอตัวแทน
  } else if (_.includes(['2101', '2103'], task)) {
    return 'resellerTasks_confirmOrder'
  } else if (_.includes(['2104'], task)) {
    return 'resellerTasks_pay'

      // รอผู้ขายส่ง
  } else if (_.includes(['3102'], task)) {
    return 'sellerTasks_confirmOrder'
  } else if (_.includes(['3105'], task)) {
    return 'sellerTasks_confirmGettingPaid'
  } else if (_.includes(['3106'], task)) {
    return 'sellerTasks_ship'
  } else if (_.includes(['1171', '2171', '3171'], task)) {
    return 'sellerTasks_process'
      // เสร็จสิ้น
  } else if (_.includes(['1162', '2162', '3162'], task)) {
    return 'doneTasks_shipped'
  } else if (_.includes(['1180', '1181', '1182', '1183', '2180', '2181', '2182', '2183',
    '3180', '3181', '3182', '3183'], task)) {
    return 'doneTasks_cancelled'
  } else {
    return false
  }
}
*/

export function getOrderOperation({ type, state }): { key?: string; message?: string } {
  const op = type.toString() + state.toString()
  const { CONFIRM, PAY, SHIP } = Constants.ORDER_VIEW_OPERATION_ITEMS

  if (_.includes(['3101', '2102', '3103'], op)) {
    // CONFIRM
    return {
      key: CONFIRM.key,
      message: 'หากทำรายการนี้จะไม่สามารถย้อนกลับได้ กดยืนยันเพื่อยืนข้อมูลใบสั่งซื้อของคุณ',
    }
  }
  if (_.includes(['2105'], op)) {
    // CONFIRM_PAY
    return {
      key: CONFIRM.key,
      message: 'กดยืนยันเพื่อยืนข้อมูลว่าคุณได้รับการชำระเงินเรียบร้อยแล้ว',
    }
  }
  if (_.includes(['1104', '3104'], op)) {
    // PAY
    return {
      key: PAY.key,
      message: 'Mock: ชำระเงินเรียบร้อยแล้ว',
    }
  }
  if (_.includes(['1106', '2106'], op)) {
    // SHIP
    return {
      key: SHIP.key,
      message: 'Mock: ยืนยันการจัดส่งของคุณ',
    }
  }
  return {
    key: null,
    message: null,
  }
}

// function getInputVariantsByMode({ mode }) {
//   // Define what ever variants you want here!
//   const { VIEW_SELF, EDIT_SELF } = Constants.PRODUCT_VIEW_MODE
//   const vObject = {
//     name: { key: 'name', icon: 'ios-create', label: 'ชื่อ',
//       placeholder: 'เช่น สีดำ', isNumber: false, readonly: true },
//     qty: { key: 'qty', icon: 'ios-albums',
//       label: _.includes([VIEW_SELF, EDIT_SELF], mode) ? 'คลัง' : 'จำนวน',
//       placeholder: 'จำนวนสินค้า', isNumber: true, readonly: true, isInt: true },
//     available_qty: { key: 'available_qty', icon: 'ios-albums', label: 'คงเหลือ',
//       placeholder: 'จำนวนสินค้า', isNumber: true, readonly: true, isInt: true },
//     // cost: { key: 'cost', icon: 'md-cash', label: 'ต้นทุน', placeholder: 'เห็นเฉพาะคุณเท่านั้น', isNumber: true, readonly: true },
//     // หลังบ้านเปลี่ยน cost เป็น c
//     cost: { key: 'cost', icon: 'md-cash', label: 'ต้นทุน',
//       placeholder: 'คุณเห็นเท่านั้น', isNumber: true, readonly: true, isMoney: true },
//     price: { key: 'price', icon: 'md-pricetag', label: 'ราคา',
//       placeholder: 'ระบุราคาขาย', isNumber: true, readonly: true, isMoney: true },
//   }
//   if (mode in Constants.PRODUCT_VIEW_VARIANTS_BY_MODE) {
//     const { variants, editable } = Constants.PRODUCT_VIEW_VARIANTS_BY_MODE[mode]
//     console.log('___Constants.PRODUCT_VIEW_VARIANTS_BY_MODE[mode]_: ', Constants.PRODUCT_VIEW_VARIANTS_BY_MODE[mode])
//     let inputList = variants.map((vName) => {
//       let input = _.cloneDeep(vObject[vName])
//       if (_.includes(editable, vName)) {
//         input.readonly = false
//       }
//       return input
//     })
//     return inputList
//   }
//   return []
// }

// function getProductVariants({ mode, parent_id, s_use_retail_price = false, order_use_retail_price = 0 }) {
//   // Define what ever variants you want here!
//   const { ADD, VIEW_SELF, EDIT_SELF, VIEW_SELLER, EDIT_SELLER,
//     PULL_PRODUCT, PULL_MY_PRODUCT_TO_ORDER, PULL_SELLER_PRODUCT_TO_ORDER } = Constants.PRODUCT_VIEW_MODE
//   const variants = [
//     { // 0
//       key: 'name', icon: 'ios-create', label: 'ชื่อ',
//       placeholder: 'เช่น สีดำ', isNumber: false,
//     },
//     { // 1
//       key: 'cost', icon: 'md-cash', label: 'ต้นทุน',
//       placeholder: 'คุณเห็นเท่านั้น', isNumber: true, isMoney: true,
//     },
//     { // 2
//       key: 'price', icon: 'md-pricetag',
//       label: s_use_retail_price ? 'ราคาขายส่ง/ตัวแทน' : 'ราคา',
//      // placeholder: s_use_retail_price ? 'ระบุราคาขาย' : 'ระบุราคาขายส่ง/ตัวแทน', isNumber: true, isMoney: true,
//       placeholder: 'ระบุราคาขาย' , isNumber: true, isMoney: true,
//     },
//     { // 3
//       key: 'qty', icon: 'ios-albums',
//       label: _.includes([ADD, VIEW_SELF, EDIT_SELF, VIEW_SELLER, EDIT_SELLER], mode) ? 'คลัง' : 'จำนวน',
//       placeholder: 'จำนวนสินค้า', isNumber: true, isInt: true,
//     },
//     { // 4
//       key: 'available_qty', icon: 'ios-albums', label: 'คลังพร้อมขาย',
//       placeholder: 'จำนวนสินค้า', isNumber: true, isInt: true,
//     },
//     { // 5
//       key: 'weight', icon: 'scale', label: 'น้ำหนัก (กรัม)',
//       placeholder: 'ระบุน้ำหนัก(กรัม)', isNumber: true, isInt: true,
//     },
//     { // 6
//       key: 'price_retail', icon: 'md-pricetag', label: 'ราคาขายปลีก',
//       placeholder: 'ระบุราคาขายปลีก', isNumber: true, isMoney: true,
//     },
//   ]
//   let usageVariants: Array<any> = []
//
//   if (_.includes([VIEW_SELF, EDIT_SELF, VIEW_SELLER, EDIT_SELLER], mode)) {
//     // ถ้าเป็น mode สำหรับดู และ มี parent_id แสดงว่าโดนดึงมา จะไม่เห็น qty
//     if (_.includes([VIEW_SELF], mode) && !parent_id) {
//       pushArrayByArray(variants, usageVariants, [0, 1, 2, 3, 4, 5])
//     } else if (_.includes([EDIT_SELF], mode) && !parent_id) {
//       pushArrayByArray(variants, usageVariants, [0, 1, 2, 3, 5])
//       // pushArrayByArray(variants, usageVariants, [0, 1, 2, 3, 4])
//     } else if (_.includes([VIEW_SELLER], mode)) {
//       pushArrayByArray(variants, usageVariants, [0, 2, 4])
//     } else {
//       pushArrayByArray(variants, usageVariants, [0, 1, 2, 4])
//     }
//   } else if (_.includes([PULL_PRODUCT], mode)) {
//     pushArrayByArray(variants, usageVariants, [0, 4, 1, 2])
//   } else if (_.includes([PULL_MY_PRODUCT_TO_ORDER], mode) && parent_id) {
//     pushArrayByArray(variants, usageVariants, [0, 1, 2, 4, 3])
//     // pushArrayByArray(variants, usageVariants, [0, 4, 1, 2, 3])
//   }  else if (_.includes([PULL_MY_PRODUCT_TO_ORDER], mode) && !parent_id) {
//     // ถ้าเป็นสินค้าร้านฉันให้แสดงน้ำหนักด้วย
//     pushArrayByArray(variants, usageVariants, [0, 1, 2, 4, 3, 5])
//     // pushArrayByArray(variants, usageVariants, [0, 4, 1, 2, 3])
//   } else if (_.includes([PULL_SELLER_PRODUCT_TO_ORDER], mode)) {
//     pushArrayByArray(variants, usageVariants, [0, 2, 4, 3])
//     // pushArrayByArray(variants, usageVariants, [0, 4, 1, 2, 3])
//   } else if (_.includes([ADD], mode)) {
//     pushArrayByArray(variants, usageVariants, [0, 1, 2, 3, 5])
//   } else {
//     pushArrayByArray(variants, usageVariants, [0, 1, 2, 4, 3])
//   }
//
//   if (s_use_retail_price) {
//     // index of price to overwrite with retail price
//     const foundIndex = usageVariants.findIndex(v => v.key === 'price')
//     if (_.includes([PULL_MY_PRODUCT_TO_ORDER, PULL_SELLER_PRODUCT_TO_ORDER], mode)) {
//       if (order_use_retail_price) {
//         usageVariants[foundIndex] = variants[6]
//       }
//     } else if (_.includes([VIEW_SELF, EDIT_SELF], mode)) {
//       usageVariants.splice(foundIndex + 1, 0, variants[6])
//     } else {
//       // View Seller's products case must be unseen retail price
//     }
//   }
//   return usageVariants
// }
//
// function pushArrayByArray(source: any, target: any, indexes: Array<any>) {
//   indexes.forEach( (idx: any) => {
//     target.push(source[idx])
//   })
// }
// interface IConstants {
//   APP_NAME: string
//   AUTHENTICATION_STORAGE_KEY: string
//   FACEBOOK_AUTH_STORAGE_KEY: string
//   BADGE_OVERFLOW_COUNT: number
//   BADGE_TYPE_BG_APP_MAIN: string
//   BADGE_TYPE_TEXT_APP_MAIN: string
//   SERVER_DATE_FORMAT: string
//   SERVER_DATETIME_FORMAT: string
//   DATE_FORMAT: string
//   ACTION_NO_OP: string
//   ORDER_TAB_KEY_SEP: string
//   ORDER_KEY_MY_TASKS: string
//   ORDER_KEY_CUST_TASKS: string
//   ORDER_KEY_RESELLER_TASKS: string
//   ORDER_KEY_SELLER_TASKS: string
//   ORDER_KEY_DONE_TASKS: string
//   ORDER_KEY_CONFIRM_ORDER: string
//   ORDER_KEY_PAY: string
//   ORDER_KEY_AUTO_COMPLETE: string
//   ORDER_KEY_CONFIRM_GETTING_PAID: string
//   ORDER_KEY_SHIP: string
//   ORDER_KEY_SHIPPED: string
//   ORDER_KEY_AUTO_COMPLETED: string
//   ORDER_KEY_CANCELLED: string
//   ORDER_KEY_PROCESS: string
//   STORES_MODE_MY_VIEW: string
//   STORES_MODE_MY_PULL: string
//   STORES_MODE_SELLER_VIEW: string
//   STORES_MODE_SELLER_PULL_PRODUCT: string
//   KEYBOARD_PERSIST_TAB_MODE: string
//   ADDRESS_LIST_MODE_KEYS: { [key: string]: string }
//   ADDRESS_MODE_KEYS: { [key: string]: string }
//   CONTACT_VIEW_KEYS: Array<{ label: string; key: string; inputType: string; required?: boolean }>
//   CONTACT_EDITABLE_ADDRESS_KEYS: Array<{ label: string; key: string; inputType: string; required?: boolean }>
//   CONTACT_CREATE_KEYS: Array<{ label: string; key: string; inputType: string; required?: boolean }>
//   CONTACT_LIST_MODE_KEYS: { [key: string]: string }
//   CONTACT_LIST_STATE_KEYS: { [key: string]: { list: string; group: string } }
//   CONTACT_VIEW_MODE: { [key: string]: string }
//   CONTACT_EDITING_MODE: { [key: string]: string }
//   CONTACT_ACTOR_KEYS: { [key: string]: string }
//   DEFAULT_KEYS: { [key: string]: string }
//   NOTIFICATION_COMMON_KEYS: string[]
//   NOTIFICATION_NAV_CASE_KEY: string
//   NOTIFICATION_PARAMS_KEYS_INTEGER: string[]
//   NOTIFICATION_PARAMS_KEYS_STRING: string[]
//   NOTIFICATION_PARAMS_KEYS_OBJECT: string[]
//   ONBOARD_MISSING_FIELDS: { [key: string]: string }
//   ORDER_VIEW_MODE: { [key: string]: string }
//   TEL_NUM_REQUIRED_FOR_SHIPPING_TYPE_IDS: number[]
//   ORDER_VIEW_SHIPPING_TYPE_ITEMS: Array<{
//     index: number
//     right: string
//     value: number
//     key: string
//     tel_required?: boolean
//   }>
//   ORDER_VIEW_CHANNEL_ITEMS: Array<{ index: number; right: string; value: number; key: string }>
//   RATE_ORDER_COUNT_BEGIN: number
//   RATE_AGAIN_IN_DAYS: number
//   RATE_APP_OPTIONS: {
//     AppleAppID: string
//     GooglePackageName: string
//     OtherAndroidURL: string
//     preferredAndroidMarket: number
//     fallbackPlatformURL: string
//     preferInApp: true
//   }
//   SUBSCRIPTION_MAIN_PACKAGE_ITEMS: Array<{ index: number; value: number; key: string }>
//   SUBSCRIPTION_PACKAGE: { [key: string]: number }
//   SUBSCRIPTION_SETTINGS: { [key: string]: number }
//   SHIPPING_TYPE_IDS: { [key: string]: number }
//   ORDER_TYPE_0: number
//   ORDER_TYPE_1: number
//   ORDER_TYPE_2: number
//   ORDER_TYPE_3: number
//   ORDER_VIEW_KEY_SUMMARY_ITEMS: Array<{ key: string; backendKey?: string; label: string; readonly?: boolean }>
//   ORDER_EDITABLE_KEY: string
//   ORDER_EDITABLE: {
//     RECEIVER_ADDR: string
//   }
//   ORDER_VIEW_KEY_SUMMARY_MINUS_KEYS: string[]
//   ORDER_VIEW_OPERATION_ITEMS: {
//     CONFIRM: { key: string; label: string }
//     PAY: { key: string; label: string }
//     SHIP: { key: string; label: string }
//     CANCEL: { key: string; label: string }
//   }
//   ORDER_VIEW_PRODUCT_CARD_ADDITION_INFO: {
//     CREATE: Array<{ key: string; isMoney?: boolean }>
//     EDIT: Array<{ key: string; isMoney?: boolean }>
//     VIEW_ONLY: Array<{ key: string; isMoney?: boolean }>
//     VIEW_EDIT: Array<{ key: string; isMoney?: boolean }>
//   }
//   ORDER_OPERATION_GET: (params: { type: number; state: number }) => { key?: string; message?: string }
//   ORDER_CANCELABLE_STATES: string[]
//   ORDER_EDITABLE_TASK_KEY: string
//   ORDERS_FETCH_MODE_INIT: string
//   ORDERS_FETCH_MODE_REFRESH: string
//   ORDERS_FETCH_MODE_MORE: string
//   ORDERS_VIEW_MODE_STORE: string
//   ORDERS_VIEW_MODE_PAYMENT: string
//   ORDERS_VIEW_MODE_SEARCH: string
//   PRODUCTS_FETCH_MODE_INIT: string
//   PRODUCTS_FETCH_MODE_REFRESH: string
//   PRODUCTS_FETCH_MODE_MORE: string
//   PRODUCTS_SEARCH_MODE_INIT: string
//   PRODUCTS_SEARCH_MODE_REFRESH: string
//   PRODUCTS_SEARCH_MODE_MORE: string
//   PRODUCTS_FETCH_LIMIT_INIT: number
//   PRODUCTS_FETCH_LIMIT_MORE: number
//   // PRODUCT VIEW
//   PRODUCT_VIEW_MODE: {
//     ADD: string
//     EDIT_SELF: string
//     EDIT_SELLER: string
//     // VIEW_ONLY: string,
//     VIEW_SELF: string
//     VIEW_SELLER: string
//     PULL_MY_PRODUCT_TO_ORDER: string
//     PULL_SELLER_PRODUCT_TO_ORDER: string
//     PULL_PRODUCT: string
//     VIEW_VOLUME_DISCOUNT: string
//   }
//   PRODUCT_FETCH_STATUS: {
//     OFFSET: string
//     SHOULD_FETCH: string
//     SHOULD_INIT_LOAD_WITH_LIMIT: string
//   }
//   PRODUCT_CATEGORY_KEY: string
//   PRODUCT_CATEGORY_ITEMS: Array<{ id: number; label: string; name: string }>
//   PRODUCT_STOCK: {
//     noStock: string
//     lowStock: string
//     noRetailPrice: string
//   }
//   BANK_INFO: Array<{ id: number; key: string; name: string; hidden?: boolean }>
//   PRODUCT_GROUP_LIST_MODE: {
//     VIEW: string
//     SORT: string
//     ASSIGN_TO_UG: string
//   }
//   PRODUCT_GROUP_VIEW_MODE: {
//     ADD: string
//     VIEW: string
//     EDIT: string
//   }
//   USER_GROUP_LIST_MODE: {
//     VIEW: string
//     CHANGE_TO_GROUP: string
//   }
//   USER_GROUP_VIEW_MODE: {
//     ADD: string
//     VIEW: string
//     EDIT: string
//   }
//   GBPAY: number
//   TIMER_ALMOST_DUE_SECONDS: number
//   PAGEPICKER_VIEWTYPE: { FLAT: string; ICON: string; SECTION: string }
//   IMGBLOCK_EMPTY_IMAGE: {
//     [key: string]: { bundleName: string; iconName: string }
//   }
//   ORDER_SUMMARY_ALL: string[]
//   PDF_URL_REQ_DATE_FORMAT: string
//   ORDER_STATE: {
//     CANCELLED_BY_SELLER: number
//     CANCELLED_BY_BUYER: number
//     CANCELLED_BY_SYSTEM: number
//     CANCELLED_BY_EXPIRED: number
//   }
//   ORDER_OPERATION_AVAILABILITY: {
//     CONFIRM: { [key: string]: number }
//     PAY: { [key: string]: number }
//     SHIP: { [key: string]: number }
//     EDIT: { [key: string]: number }
//   }
//   API: {
//     // GET
//     GET_CUSTOMERS: string
//     GET_PROFILE: string
//     GET_REFRESH: string
//     GET_SELLER_STORES: string
//     GET_STORES: string
//     GET_VERIFICATION_EMAIL: string
//
//     // GET_BANKACCOUNTS: string,
//     // POST
//     POST_LOGIN: string
//
//     POST_FORGOT_PASSWORD: string
//     POST_RESET_PASSWORD: string
//
//     POST_FB_LOGIN: string
//     POST_FB_SIGNUP: string
//     POST_SIGNUP: string
//     POST_STORE: string
//     POST_BANKACCOUNTS: string
//     POST_PRODUCT: string
//     POST_PRODUCTS: string
//     POST_SELLER_STORES: string
//     POST_SELLER_PRODUCTS: string
//
//     POST_MEMBERS: string
//     POST_MEMBERS_PRE_APPROVED: string
//
//     POST_CUSTOMER: string
//     POST_ORDERS: string
//     POST_ORDER: string
//     POST_ORDER_CONFIRM: string
//     POST_ORDER_CANCEL: string
//     POST_ORDER_PAY: string
//     // POST_ORDER_SHIP: string,
//     POST_PAYMENT_CONFIRM: string
//     POST_PAYMENTS: string
//     POST_NOTIFICATION: string
//     POST_SHIPPING_AUTO_COMPLETE: string
//     POST_SHIPPING_CANCEL: string
//
//     // PUT
//     PUT_CUSTOMER: string
//     PUT_ADDRESS: string
//     PUT_CREATE_STORE: string
//     PUT_BANKACCOUNTS: string
//     PUT_PRODUCT_ADD: string
//     PUT_ORDER_CREATE: string
//     PUT_RESELLER: string
//     PUT_RESELLER_PREAPPROVE_FB: string
//     PUT_PRODUCT_PULL: string
//     PUT_PAYMENT: string
//     PUT_ORDER_SHIP: string
//     PUT_ONBOARD: string
//
//     // PATCH
//     PATCH_USERNAME: string
//     PATCH_PROFILE: string
//     PATCH_ADDRESS: string
//     PATCH_PRODUCT: string
//     PATCH_CUSTOMER: string
//     PATCH_MY_STORE: string
//     PATCH_ORDER_RECEIVER_ADDRESS: string
//     PATCH_BANKACCOUNTS: string
//     PATCH_CHANGE_EMAIL: string
//     PATCH_ORDER_SETTING: string
//
//     // DEL
//     DELETE_PRODUCT: string
//     DELETE_BANKACCOUNT: string
//   }
//   BTN_MODE: {
//     ACTIVE_PRIMARY: number
//     ACTIVE_SECONDARY: number
//     INACTIVE: number
//   }
//   EVENT_NAME: {
//     SHARE_IMG: string
//     ORDER_CALC_SHIPPING_COST_SUCCESS: string
//     ORDER_CALC_SHIPPING_COST_ERROR: string
//     ORDER_CALC_SHIPPING_COST_FAILED: string
//     REPORT_DAILY: string
//     REPORT_DAILY_EVENTS: string[]
//     REPORT_DAILY_SELECT_PERIOD: string
//     REPORT_DAILY_PRINT_EXCEL: string
//     REPORT_RESELLER: string
//     REPORT_SHIPPING: string
//     REPORT_REQUISITION: string
//     HELP_VIEW_MY_STORES: string
//     HELP_VIEW_SETTINGS: string
//     ORDER_PUBLIC_LINK_SHARE: string
//   }
//   STORE_ROLE: {
//     OWNER: number
//     HELPER: number
//     RESELLER: number
//   }
//   STORE_SETTINGS: {
//     AUTO_CALC_SHIPPING_COST: string
//     SHIPPING_RATE_BY_WEIGHT_AT_COUNT: string
//     SHIPPING_CALC_MODE: string
//     SHIPPING_CALC_MODE_QTY: number
//     SHIPPING_CALC_MODE_WEIGHT: number
//     SHIPPING_CALC_MODE_QTY_AND_WEIGHT: number
//     CALC_SHIPPING_MEASURE_QTY: number
//     CALC_SHIPPING_MEASURE_WEIGHT: number
//
//     // Binary Flags
//     BIN_USE_PRODUCT_BARCODE: string
//
//     // Front-end Compute after fetch
//     COMPUTED_USE_PRODUCT_SKU: string
//     COMPUTED_USE_PRODUCT_UPC: string
//   }
//   SELECTED_STORE_LOCAL_CONFIGS: {
//     CAN_AUTO_CALC_SHIPPING_RATE_BY_QTY: string
//     CAN_AUTO_CALC_SHIPPING_RATE_BY_WEIGHT: string
//   }
//   STORAGE_KEYS: {
//     APP_PROPERTY: string
//     F_UNKNOWN_DEVICE_ALERT: string
//     FCM_TOKEN: string
//     FCM_NOTI_NAV_CACHE: string
//     FCM_MESSAGES: string
//     USER_PROFILE: string
//     FBDL_INIT: string
//     RNDL_INIT: string
//     FBDL_LATEST: string
//     RNDL_LATEST: string
//     SUBSCRIPTION_ORDER_TRACKER: string
//     SUBSCRIPTION_PACKAGE_TRACKER: string
//     REPORT_REQUIS_SHOW_COST: string
//     REPORT_REQUIS_WAIT_TO_REFUND_ORDERS: string
//     REPORT_REQUIS_WAIT_TO_SHIP_ORDERS: string
//     REPORT_REQUIS_SHOW_TOTAL_COST: string
//   }
//   PERM_STORE_OWNER_ONLY: string
//   PERM_STORE_HELPER: {
//     ORDER_LIST: string
//     ORDER_ADD: string
//     ORDER_EDIT: string
//     ORDER_CANCEL: string
//     ORDER_PRINT_SHIPPING: string
//     PRODUCT_LIST: string
//     PRODUCT_ADD: string
//     PRODUCT_PULL: string
//     PRODUCT_EDIT: string
//     PRODUCT_DELETE: string
//     PRODUCT_COST: string
//     PRODUCT_PRICE: string
//     // PRODUCT_STOCK: string // <<<----- Fixed with PRODUCT_STOCK_(VIEW/EDIT)
//     PRODUCT_STOCK_VIEW: string
//     PRODUCT_STOCK_EDIT: string
//     PRODUCT_SHIPPING_QTY_RATE: string
//     PRODUCT_WEIGHT: string
//     REPORT_VIEW: string
//     MEMBER_LIST: string
//     MEMBER_ADD: string
//     MEMBER_EDIT: string
//     MEMBER_DELETE: string
//     UG_LIST: string
//     UG_ADD: string
//     UG_EDIT: string
//     UG_DELETE: string
//     PG_LIST: string
//     PG_ADD: string
//     PG_EDIT: string
//     PG_DELETE: string
//     VD_LIST: string
//     PAYMENT_LIST: string
//     PAYMENT_ADD: string
//     PAYMENT_EDIT: string
//     PAYMENT_DELETE: string
//     SHIPPING_LIST: string
//     SHIPPING_ADD: string
//     SHIPPING_EDIT: string
//     SHIPPING_DELETE: string
//     SHOPPING_ASSISTANT_ADD: string
//     SHOPPING_ASSISTANT_EDIT: string
//     SHOPPING_ASSISTANT_DELETE: string
//     SHOPPING_ASSISTANT_LIST: string
//
//     // TODO: Binary code [2 = sku / 1 = upc] get from 'product_code_edit'
//     PRODUCT_CODE_EDIT_SKU: string
//     PRODUCT_CODE_EDIT_UPC: string
//   }
//   PRODUCT_ATTR: {
//     NAME: string
//     COST: string
//     PRICE: string
//     PRICE_RETAIL: string
//     QTY: string
//     AVAILABLE_QTY: string
//     WEIGHT: string
//     H: string
//     SKU: string
//     UPC: string
//   }
//   REPORT_DAILY_DATE_FORMAT: string
//   TOAST_TYPE: {
//     SUCCESS: string
//     DANGER: string
//     WARNING: string
//   }
//   ERR_CODE: {
//     // user_not_found: string,
//     LOGIN_ACCESS_DENIED: string
//     USER_NOT_FOUND: string
//     PERMISSION_DENIED: string
//     UPC_CLOSE_FLAG: string
//     SKU_CLOSE_FLAG: string
//     DUPLICATED_EMAIL: string
//     DUPLICATED_USERNAME: string
//
//     TOKEN_EXPIRED: string
//     TOKEN_INVALID: string
//     TOKEN_NOT_PROVIDED: string
//   }
//   ALOG: {
//     API_ERROR: string
//     API_CANNOT_PARSE_ERROR: string
//     API_ERROR_PERM_DENIED: string
//     API_ERROR_UNAUTH: string
//     API_ERROR_UNKNOWN: string
//     API_ERROR_UNPROCESSABLE_ENTITY: string
//     RETRY: string
//     RETRY_FAILED: string
//   }
//
//   COPY_LINK_ORDER: {
//     COPY_LINK: string
//     SHARE_LINK: string
//     OPEN_LINK: string
//   }
//
//   [key: string]: any // and Other constants
// }

// Load system bank id's values into a new array for easier use
Constants.SYSTEM_BANK_IDS_ARRAY = Object.values(Constants.SYSTEM_BANK_IDS)

export default Constants
